import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  productImage: {
    width: "100px",
    height: "100px",
  },
  productName: {
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  paperNotFoundContent: {
    padding: "70px 0",
    textAlign: "center",
  },
  paperNotFoundEmoji: {
    fontSize:"3rem",
  },
  paperNotFoundText: {
    fontWeight: "bold",
  },
  gridButton: {
    marginTop: "1rem",
  },
  gridButtonContainer: {
    textAlign: "right",
  },
  deleteButton: {
    color: "var(--mate-red)",
  },
  breadCrumbsText:{
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  contact: {
    marginTop: "1rem",
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "1fr",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1f 1fr",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    },
  },
  rowWhite:{
    backgroundColor: "#ffffff",
  },
  rowGray:{
    backgroundColor: theme.palette.action.hover,
  },
  modal:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody:{
    padding: "0.5rem 1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    width: "450px",
  },
  modalTitle:{
    marginBottom: "1rem",
    position: "relative",
  },
  modalClose : {
    position: "absolute",
    top: "-0.25rem",
    display: "block",
    right: "0rem",
    fontSize: "1.75rem",
    cursor: "pointer",
  },
  modalFooter:{
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  modalSubmit: {
    backgroundColor: "var(--blue)",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "var(--blue)",
      opacity: 0.8,
    },
  },
}));
