import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const Layout = ({
  dataRegistrationPermission,
  onRemoveRegistration,
  loadingAdd,
  dataPriceLists,
  onCloseModalAddNewRegistrationPermission,
  onOpenModalAddNewRegistrationPermission,
  openModalAddNewRegistrationPermission,
  addRegistrationFormValues,
  onChangeAddRegister,
  onAddRegistration,
  onChangeMassiveDelete,
  checkboxMassiveDelete,
  deleteAllSelected,
  allDeleteChecked,
  openModalConfirmDeleteAllPermission,
  onCloseModalConfirmDeleteAllPermission,
  onOpenModalConfirmDeleteAllPermission,
  loadingRemoveAllPermission,
  registrationPermissionVatNumberError,
  registrationPermissionCodeError,
  onChangeSearch,
  search,
  onChangeSort,
  sort,
  onEditPermission,
  openModalEditRegistration,
  editRegistrationFormValues,
  onCloseModalEditRegistration,
  onChangeEditRegister,
  onConfirmEditRegister
}) => {
  const style = Style();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom:"0" }}>
          <Typography variant="h4">
            Permessi
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} style={{ paddingBottom:"0", paddingTop:"0" }}>
          <Button
            onClick={onOpenModalAddNewRegistrationPermission}
            variant="contained"
            size="medium"
            color="primary"
            className={style.buttonAddNew}
          >
            Aggiungi Permesso
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom:"0", paddingTop:"0" }}>
          <TextField
            className={style.searchField}
            variant="outlined"
            margin="normal"
            id="search"
            label="Cerca Permesso"
            size="small"
            value={search}
            onChange={onChangeSearch}
            defaultValue={""}
          />
        </Grid>
        <Grid item xs={12}>
          {
            dataRegistrationPermission && dataRegistrationPermission.sortPermissionsAdmin.length > 0 ? (
              <>
                <FormControl variant="outlined" margin="normal" fullWidth size="small" className={style.selectSort}>
                  <InputLabel>Ordina per</InputLabel>
                  <Select
                    name="list_type_0"
                    label="Listino Estivo"
                    onChange={onChangeSort}
                    value={sort}
                    size="small"
                  >
                    <div style={{textAlign: "center"}}><b>Ascendente</b></div>
                    <MenuItem value="id-asc">ID <ArrowUpwardIcon style={{fontSize: "1.1rem"}}/></MenuItem>
                    <MenuItem value="vat_number-asc">Partita Iva <ArrowUpwardIcon style={{fontSize: "1.1rem"}}/></MenuItem>
                    <MenuItem value="code-asc">Codice <ArrowUpwardIcon style={{fontSize: "1.1rem"}}/></MenuItem>
                    <Divider />
                    <div style={{textAlign: "center"}}><b>Discendente</b></div>
                    <MenuItem value="id-desc">ID <ArrowDownwardIcon style={{fontSize: "1.1rem"}}/></MenuItem>
                    <MenuItem value="vat_number-desc">Partita Iva <ArrowDownwardIcon style={{fontSize: "1.1rem"}}/></MenuItem>
                    <MenuItem value="code-desc">Codice <ArrowDownwardIcon style={{fontSize: "1.1rem"}}/></MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  className={style.selectAllWrapper}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={onChangeMassiveDelete}
                      name="all"
                      checked={allDeleteChecked}
                    />
                  }
                  label="Seleziona tutto"
                />
                <Button
                  variant="contained"
                  size="small"
                  disabled={checkboxMassiveDelete.length > 0 ? false : true}
                  onClick={onOpenModalConfirmDeleteAllPermission}
                >
                  Elimina selezionati
                </Button>
                <TableContainer component={Paper}>
                  <Table className={style.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="left"></StyledTableCell>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="center" >Partita IVA</StyledTableCell>
                        <StyledTableCell align="center" >Code</StyledTableCell>
                        <StyledTableCell align="center">Listino Estivo</StyledTableCell>
                        <StyledTableCell align="center">Listino Invernale</StyledTableCell>
                        <StyledTableCell align="center">Data Permesso Registrazione</StyledTableCell>
                        <StyledTableCell align="center">Modifica</StyledTableCell>
                        <StyledTableCell align="center">Remove</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {
                        search ? (
                          dataRegistrationPermission.sortPermissionsAdmin.filter(item => item.code.toLowerCase().includes(search.toLowerCase()) || item.vat_number.toLowerCase().includes(search.toLowerCase()) ).map((item, index) => (
                            <StyledTableRow key={index} >
                              <StyledTableCell align="left" style={{width: "50px"}}>
                                <Checkbox
                                  color="primary"
                                  name={item.id}
                                  onChange={onChangeMassiveDelete}
                                  checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">{index + 1}</StyledTableCell>
                              <StyledTableCell align="center">{item.vat_number}</StyledTableCell>
                              <StyledTableCell align="center">{item.code}</StyledTableCell>
                              <StyledTableCell align="center">
                                {dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === item.list_type_0 && listItem.type === 0)].name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === item.list_type_1 && listItem.type === 1)].name}
                              </StyledTableCell>
                              <StyledTableCell align="center">{item.created_at}</StyledTableCell>
                              <StyledTableCell align="center">
                                <IconButton aria-label="edit" className={style.margin} size="small" onClick={() => onEditPermission(item.id)}>
                                  <EditIcon/>
                                </IconButton>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <IconButton aria-label="delete" className={style.margin} size="small" onClick={() => onRemoveRegistration(item.id)}>
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          dataRegistrationPermission.sortPermissionsAdmin.map((item, index) => {
                            return (
                              <StyledTableRow key={index} >
                                <StyledTableCell align="left" style={{width: "50px"}}>
                                  <Checkbox
                                    color="primary"
                                    name={item.id}
                                    onChange={onChangeMassiveDelete}
                                    checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                <StyledTableCell align="center">{item.vat_number}</StyledTableCell>
                                <StyledTableCell align="center">{item.code}</StyledTableCell>
                                <StyledTableCell align="center">
                                  {dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === item.list_type_0 && listItem.type === 0)].name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === item.list_type_1 && listItem.type === 1)].name}
                                </StyledTableCell>
                                <StyledTableCell align="center">{item.created_at}</StyledTableCell>
                                <StyledTableCell align="center">
                                <IconButton aria-label="edit" className={style.margin} size="small" onClick={() => onEditPermission(item.id)}>
                                  <EditIcon/>
                                </IconButton>
                              </StyledTableCell>
                                <StyledTableCell align="center">
                                  <IconButton aria-label="delete" className={style.margin} size="small" onClick={() => onRemoveRegistration(item.id)}>
                                    <DeleteIcon color="error" />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (<p style={{ margin: "0"}}>Non ci sono permessi di registrazione</p>)
          }
        </Grid>
      </Grid>
      <Modal
        open={openModalAddNewRegistrationPermission}
        onClose={onCloseModalAddNewRegistrationPermission}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalAddNewRegistrationPermission}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Aggiungi nuovo permesso di registrazione
          </Typography>
          <TextField
            name="vatNumber"
            onChange={onChangeAddRegister}
            value={addRegistrationFormValues["vatNumber"]}
            error={registrationPermissionVatNumberError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Partita IVA"
            autoFocus
          />
          <TextField
            name="code"
            onChange={onChangeAddRegister}
            value={addRegistrationFormValues["code"]}
            error={registrationPermissionCodeError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Code"
            autoFocus
          />
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Estivo</InputLabel>
            <Select
              name="list_type_0"
              onChange={onChangeAddRegister}
              value={addRegistrationFormValues["list_type_0"]}
              label="Listino Estivo"

            >
              {
                dataPriceLists.priceLists.filter(element => element.type === 0).map((item) => {
                  return(
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Invernale</InputLabel>
            <Select
              name="list_type_1"
              onChange={onChangeAddRegister}
              value={addRegistrationFormValues["list_type_1"]}
              label="Listino Invernale"
            >
              {
                dataPriceLists.priceLists.filter(element => element.type === 1).map((item) => {
                  return(
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          {
            registrationPermissionCodeError ||  registrationPermissionVatNumberError ? (
              <Typography variant="caption" color="error">Inserire tutti i campi e verificare che la partita iva non sia già esistente</Typography>
            ) : (
              <></>
            )
          }
          {
            loadingAdd ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <Button
                    type="submit"
                    onClick={onCloseModalAddNewRegistrationPermission}
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type="submit"
                    onClick={onAddRegistration}
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteAllPermission}
        onClose={onCloseModalConfirmDeleteAllPermission}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalConfirmDeleteAllPermission}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare <b>{checkboxMassiveDelete.length}</b> {checkboxMassiveDelete.length === 0 ? "permesso di registrazione " : "permessi di registrazione "}?
            </Typography>
          </Grid>
          {
            loadingRemoveAllPermission ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseModalConfirmDeleteAllPermission}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={deleteAllSelected}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalEditRegistration}
        onClose={onCloseModalEditRegistration}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalEditRegistration}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Modifica permesso di registrazione
          </Typography>
          <TextField
            name="vatNumber"
            onChange={onChangeEditRegister}
            value={editRegistrationFormValues["vatNumber"]}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Partita IVA"
            autoFocus
          />
          <TextField
            name="code"
            onChange={onChangeEditRegister}
            value={editRegistrationFormValues["code"]}
            error={registrationPermissionCodeError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Code"
            autoFocus
          />
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Estivo</InputLabel>
            <Select
              name="list_type_0"
              onChange={onChangeEditRegister}
              value={editRegistrationFormValues["list_type_0"]}
              label="Listino Estivo"

            >
              {
                dataPriceLists.priceLists.filter(element => element.type === 0).map((item) => {
                  return(
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Invernale</InputLabel>
            <Select
              name="list_type_1"
              onChange={onChangeEditRegister}
              value={editRegistrationFormValues["list_type_1"]}
              label="Listino Invernale"
            >
              {
                dataPriceLists.priceLists.filter(element => element.type === 1).map((item) => {
                  return(
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          {
            loadingAdd ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <Button
                    type="submit"
                    onClick={onCloseModalEditRegistration}
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type="submit"
                    onClick={onConfirmEditRegister}
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
