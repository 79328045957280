import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Badge from "@material-ui/core/Badge";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import StorefrontIcon from "@material-ui/icons/Storefront";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CategoryIcon from "@material-ui/icons/Category";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import PaletteIcon from "@material-ui/icons/Palette";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LockIcon from "@material-ui/icons/Lock";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Logo from "../../../assets/images/CashmereCompanyLogo.png";

import Style from "./style.js";

const Layout = ({
  history,
  children,
  userMenu,
  openUserMenu,
  anchorMenu,
  closeUserMenu,
  onLogout,
  dataMe,
  dataNumberNewOrders,
  mobileOpen,
  onClickMenu,
}, props) => {
  const { window } = props;
  const style = Style();
  const theme = useTheme();
  const drawer = (
    <div className={style.sidebarWrapper}>
      <div className={style.toolbar} />
      <Divider />
      <List>
        <ListItem button onClick={() => history.push("/admin")} selected={history.location.pathname === "/admin" ? true : false}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin" ? style.activeMenuText : style.menuText}>
            Dashboard
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/users")} selected={history.location.pathname === "/admin/users" ? true : false}>
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/users" ? style.activeMenuText : style.menuText}>
            Utenti
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/permissions")} selected={history.location.pathname === "/admin/permissions" ? true : false}>
          <ListItemIcon><LockIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/permissions" ? style.activeMenuText : style.menuText}>
            Permessi
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/products")} selected={history.location.pathname === "/admin/products" ? true : false}>
          <ListItemIcon><StorefrontIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/products" ? style.activeMenuText : style.menuText}>
            Prodotti
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/orders")} selected={history.location.pathname === "/admin/orders" ? true : false}>
          <ListItemIcon>
            <Badge badgeContent={dataNumberNewOrders === undefined ? 0 : dataNumberNewOrders.getNumberOfNewOrders} color="primary">
              <LocalShippingIcon />
            </Badge>
          </ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/orders" ? style.activeMenuText : style.menuText}>
            Ordini
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/lists")} selected={history.location.pathname === "/admin/lists" ? true : false}>
          <ListItemIcon><ListAltIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/lists" ? style.activeMenuText : style.menuText}>
            Listini
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/categories")} selected={history.location.pathname === "/admin/categories" ? true : false}>
          <ListItemIcon><CategoryIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/categories" ? style.activeMenuText : style.menuText}>
            Categorie
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/sizes")} selected={history.location.pathname === "/admin/sizes" ? true : false}>
          <ListItemIcon><AspectRatioIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/sizes" ? style.activeMenuText : style.menuText}>
            Taglie
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/colors")} selected={history.location.pathname === "/admin/colors" ? true : false}>
          <ListItemIcon><PaletteIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/colors" ? style.activeMenuText : style.menuText}>
            Colori
          </Typography>
        </ListItem>
        <ListItem button onClick={() => history.push("/admin/agents")} selected={history.location.pathname === "/admin/agents" ? true : false}>
          <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
          <Typography variant="body1" gutterBottom className={history.location.pathname === "/admin/agents" ? style.activeMenuText : style.menuText}>
            Agenti
          </Typography>
        </ListItem>
      </List>
    </div>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={style.root}>
      <CssBaseline />
      <AppBar position="fixed" className={style.appBar}>
        <Toolbar style={{ background: 'white' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onClickMenu}
            className={style.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={Logo} alt="logo" height="50px" width="auto" style={{ margin: "10px", cursor: "pointer" }} />
          <div className={style.wrapperUserMenu}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              style={{ color: theme.palette.primary.main }}
              onClick={openUserMenu}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorMenu}
            keepMounted
            open={userMenu}
            onClose={closeUserMenu}
          >
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav className={style.drawer} >
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={onClickMenu}
            style={{
              paper: style.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation="css">
          <Drawer
            style={{
              paper: style.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <Container maxWidth="xl">
        <main className={style.content}>
          <div className={style.toolbar} />
          {children}
        </main>
      </Container>
    </div>
  );
};

export default Layout;
