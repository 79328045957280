import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 250;

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('xl')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 0),
  },
  // Custom
  sidebarWrapper: {
    width: drawerWidth,
  },
  wrapperUserMenu: {
    position: "absolute",
    right: "1rem",
  },
  textUserMenu: {
    textTransform: "lowercase",
    display: "block",
    padding: "0.5rem",
  },
  menuText: {
    color: "#777777",
  },
  activeMenuText: {
    fontWeight: "600",
    color: "#777777",
  },
  linkMenu:{
    "&:hover": {
      textDecoration: "none",
    },
  },
}));
