import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  dataPriceListProducts,
  onChangeForm,
  search,
  onChangeSearch,
  dataPriceListById,
  productPricesFormValues,
  onSaveListPrice,
  loading,
}) => {
  const style = Style();
  return (
    <>
      <Grid container >
        <Grid item xs={12}>
          <Typography variant="h4">
            Listino {dataPriceListById.priceListById.name} - {dataPriceListById.priceListById.type === 0 ? " Estivo" : " Invernale"}
          </Typography>
          <Typography variant="h4">
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={{marginBottom:"1rem"}}
            //error={errorAddPriceList}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="search"
            label="Cerca Prodotto"
            name="Cerca Prodotto"
            size="small"
            autoFocus
            value={search}
            onChange={onChangeSearch}
          />
        </Grid>
        <Grid item xs={12}>
          {
            dataPriceListProducts && dataPriceListProducts !== undefined && dataPriceListProducts.priceListProducts.length > 0 ? (
            <>
              <TableContainer>
                <Table className={style.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="left">#</StyledTableCell>
                      <StyledTableCell align="center">Immagine</StyledTableCell>
                      <StyledTableCell align="center">Nome</StyledTableCell>
                      <StyledTableCell align="center">Codice</StyledTableCell>
                      <StyledTableCell align="center">Prezzo</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {
                      search ? (
                        dataPriceListProducts.priceListProducts.filter(item => item.product_name.toLowerCase().includes(search) || item.product_code.toLowerCase().includes(search)).map((item, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">{index+1}</StyledTableCell>
                            <StyledTableCell align="center"><img src={item.product_image} width="60" height="70" alt={item.product_name}/></StyledTableCell>
                            <StyledTableCell align="center">{item.product_name}</StyledTableCell>
                            <StyledTableCell align="left">{item.product_code}</StyledTableCell>
                            <StyledTableCell align="center">
                              <TextField
                                className={style.textFieldPriceProduct}
                                variant="outlined"
                                type="number"
                                margin="normal"
                                required
                                label="Prezzo"
                                name={item.product_id}
                                size="small"
                                value={productPricesFormValues[item.product_id] ? productPricesFormValues[item.product_id] : item.price}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">€ </InputAdornment>,
                                }}
                                onChange={onChangeForm}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ):(
                        dataPriceListProducts.priceListProducts.map((item, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="left">{index+1}</StyledTableCell>
                            <StyledTableCell align="center"><img src={item.product_image} width="60" height="70" alt={item.product_name} /></StyledTableCell>
                            <StyledTableCell align="center">{item.product_name}</StyledTableCell>
                            <StyledTableCell align="center">{item.product_code}</StyledTableCell>
                            <StyledTableCell align="center">
                                <TextField
                                  className={style.textFieldPriceProduct}
                                  variant="outlined"
                                  type="number"
                                  margin="normal"
                                  required
                                  label="Prezzo"
                                  name={item.product_id}
                                  size="small"
                                  value={productPricesFormValues[item.product_id] ? productPricesFormValues[item.product_id] : item.price}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">€ </InputAdornment>,
                                  }}
                                  onChange={onChangeForm}
                                />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid className={style.buttonActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    onClick={() => window.history.back()}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    onClick={onSaveListPrice}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            </>
            ) : (<p>non ci sono prodotti in questo listino</p>)
          }
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
