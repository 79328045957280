import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HomeIcon from "@material-ui/icons/Home";
import Container from "@material-ui/core/Container";
import Logo from "../../../assets/images/CashmereCompanyLogo.png";
import LogoNoWrite from "../../../assets/images/Logo2.png";
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

import Style from "./style.js";
import PromoPopup from "../../promoPopup/index.js";

const Layout = ({
  children,
  userMenu,
  anchorMenu,
  openUserMenu,
  closeUserMenu,
  onLogout,
  dataMe,
  onGoToHome,
  onGoToCart,
  onGoToFavorites,
  onGoToOrders,
  onGoToMyAccount,
  history,
  isMobile,
  searchMenu,
  anchorSearchMenu,
  openSearchMenu,
  closeSearchMenu,
  search,
  setSearch,
  onChangeSearch,
  onClickSearch
}) => {
  const style = Style();

  const [showPopup, setShowPopup] = useState(false)
  const [hidePrice, setHidePrice] = useState(localStorage.getItem('hidePricingAgent'))

  useEffect(() => {
    if (localStorage.getItem('popupToken') === null) {
      setShowPopup(true);
    }
    if (localStorage.getItem('hidePricingAgent')) {
      setHidePrice(true);
    }
  }, []);

  return (
    <>
      <div className={style.header}>
        <AppBar className={style.appBar}>
          <Toolbar>
            <div className={style.title}>
              <img src={Logo} alt="logo"  onClick={onGoToHome} className={isMobile ? style.titleImageNoWrite : style.titleImage} />
            </div>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="primary"
              style={{padding: "8px"}}
              onClick={() => {
                if (localStorage.getItem('hidePricingAgent')) {
                  setHidePrice(false);
                  localStorage.removeItem("hidePricingAgent");
                } else {
                  setHidePrice(true);
                  localStorage.setItem("hidePricingAgent", 1);
                }
                window.dispatchEvent(new Event("storage"));
              }}
            >
              {hidePrice ? <MoneyOffIcon color="primary" /> : <MoneyOffIcon />}
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="primary"
              onClick={openSearchMenu}
              style={{padding: "8px"}}
            >
              <SearchIcon />
            </IconButton>
            <Menu
              id="simple-menu-search"
              anchorEl={anchorSearchMenu}
              keepMounted
              open={searchMenu}
              onClose={closeSearchMenu}
            >
              <MenuItem>
                <form>
                  <TextField autoFocus id="outlined-basic" size="small" type="text" variant="outlined" name="search" value={search} onChange={event => onChangeSearch(event)} style={{ marginRight: "8px" }} />
                  <Button type="submit" variant="contained" color="primary" onClick={event => onClickSearch(event)} disabled={!search}>Cerca</Button>
                </form>
              </MenuItem>
            </Menu>
            {/* <IconButton aria-label="show favorite" color="inherit" onClick={onGoToHome}>
              <HomeIcon />
            </IconButton> */}
            <IconButton aria-label="show favorite" color="primary" onClick={onGoToFavorites} style={{padding: "8px"}}>
              <Badge badgeContent={dataMe.me.favorites.length} classes={{ badge: style.badge }}>
                <FavoriteIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show cart" color="primary" onClick={onGoToCart} style={{padding: "8px"}}>
              <Badge badgeContent={dataMe.me.cart.length} classes={{ badge: style.badge }}>
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="primary"
              onClick={openUserMenu}
              style={{padding: "8px"}}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorMenu}
              keepMounted
              open={userMenu}
              onClose={closeUserMenu}
            >
              <MenuItem onClick={onGoToOrders}>I miei ordini</MenuItem>
              <MenuItem onClick={onGoToMyAccount}>Il mio account</MenuItem>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </div>
      <Container maxWidth="xl">
        <PromoPopup />
        {children}
      </Container>
      <Modal
        open={false} //disabled
        onClick={() => { setShowPopup(false); localStorage.setItem("popupToken", true); }}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <Typography variant="body1" color="primary" align="center" gutterBottom>
            <Typography variant="h4" gutterBottom>ATTENZIONE</Typography>
            Gentile cliente, si comunica che per tutti gli ordini di riassortimento effettuati tramite questa piattaforma, la modalità di pagamento sarà contrassegno al corriere 30 gg. f.m.
          </Typography>
          <Button
            style={{ marginTop: 16 }}
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            onClick={() => { setShowPopup(false); localStorage.setItem("popupToken", true); }}
          >
            Chiudi
          </Button>
        </Grid>
      </Modal>
    </>
  );

};

export default withRouter(Layout);
