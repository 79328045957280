import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor:  theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  dataPriceList,
  openModalConfirmDeletePriceList,
  onCloseConfirmDeletePriceList,
  onOpenConfirmDeletePriceList,
  onRemovePriceList,
  idPriceListToRemove,
  onChangeForm,
  newListName,
  onAddPriceList,
  errorAddPriceList,
  onViewPriceList,
  loadingRemovePriceList,
  nameListToDelete,
}) => {
  const style = Style();
  return (
    <>
      <Grid xl={12}>
        <Typography variant="h4">
          Listini Prezzo
        </Typography>
        <Grid>
          <TextField
            className={style.textFieldAddNew}
            error={errorAddPriceList}
            variant="outlined"
            margin="normal"
            required
            id="email"
            label="Nome Listino"
            name="Nome Listino"
            size="small"
            value={newListName}
            onChange={onChangeForm}
          />
          <Button
          className={style.buttonAddNew}
          variant="contained"
          size="medium"
          onClick={onAddPriceList}
          disabled={newListName === null || newListName === undefined || newListName === "" ? true : false}
          >
            Aggiungi
          </Button>
        </Grid>
        {
          errorAddPriceList ? (
            <Typography variant="caption" color="error" style={{paddingLeft: "1rem"}}>Inserire un nome e verificare che non sia già esistente</Typography>
          ) : (
            <></>
          )
        }
        <Grid item xs={12} style={{ marginTop:"1rem" }}>
          {
            dataPriceList && dataPriceList.priceLists.length > 0 ? (
              <TableContainer>
                <Table className={style.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="left">#</StyledTableCell>
                      <StyledTableCell align="center">Nome</StyledTableCell>
                      <StyledTableCell align="center">Data Creazione</StyledTableCell>
                      <StyledTableCell align="center">Stagione</StyledTableCell>
                      <StyledTableCell align="center">Elimina</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {
                      dataPriceList.priceLists.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i).map((item, index) => {
                        return (
                          <StyledTableRow key={index} >
                            <StyledTableCell align="left">{index + 1}</StyledTableCell>
                            <StyledTableCell align="center">{item.name}</StyledTableCell>
                            <StyledTableCell align="center">
                              {new Date(item.created_at.substr(0, 10)).toLocaleDateString("en-US")}
                            </StyledTableCell>
                            <StyledTableCell align="center" >
                              <div className={style.buttonTypeWrapper}>
                                <Button key={index} variant="contained" color="primary" onClick={() => onViewPriceList(item.id)} style={{margin: "0 0.25rem"}}>
                                  Estivo
                                </Button>
                                <Button key={index} variant="contained" color="primary" onClick={() => onViewPriceList(parseInt(item.id)+1)} style={{margin: "0 0.25rem"}}>
                                  Invernale
                                </Button>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <IconButton key={index}  onClick={() => onOpenConfirmDeletePriceList(item)}>
                                <DeleteIcon color="error" size="default" />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (<p>Non ci sono listini</p>)
          }
        </Grid>
      </Grid>
      <Modal
        open={openModalConfirmDeletePriceList}
        onClose={onCloseConfirmDeletePriceList}
        className={style.modal}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseConfirmDeletePriceList}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare <b>Listino {nameListToDelete}</b>?
            </Typography>
          </Grid>
          {
            loadingRemovePriceList ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseConfirmDeletePriceList}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={() => onRemovePriceList(idPriceListToRemove, idPriceListToRemove+1)}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
