import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Style from "./style.js";
import Logo from "../../../../assets/images/CashmereCompanyLogo.png";

const Form = ({
  formValues,
  onChangeForm,
  loginError,
  passwordResetEmailError,
  passwordResetEmailResponse,
  loadingPasswordReset,
  onLogin,
  openModalForgotPassword,
  closeModalForgotPassword,
  modalForgotPassword,
  onPasswordReset,
}) => {
  const style = Style();
  return (
    <>
      <Container component="main" maxWidth="xs">
        <div className={style.paper}>
          <img src={Logo} alt="logo" height="60px" width="auto" style={{ marginBottom: "60px"}} />
          <Typography variant="h5" align="center" color="primary" style={{ letterSpacing: "8px" }}><b>ACCEDI</b></Typography>
          <Paper style={{ padding: "6px", display: "flex", gap: "10px", alignItems: "top", marginTop: "8px", background: "#ffffff" }}>
            {/* <img src={Logo} alt="logo" height="40px" style={{marginTop: "17px"}} /> */}
            <Typography style={{ fontSize: "12px", color: "#88695f" }}>
              <b style={{ color: "#88695f" }}>ATTENZIONE</b><br />
              Se sei titolare di un negozio di abbigliamento puoi richiedere l’accesso al nostro B2B scrivendo a <a href="mailto:commerciale@blucashmere.eu" target="_blank" style={{ textDecoration: "none", color: "#88695f" }}><b>commerciale@blucashmere.eu</b></a> oppure chiamando al numero <a href="tel:+390758038799" target="_blank" style={{ textDecoration: "none", color: "#88695f" }}><b>075.80.38.799</b></a>.<br /><br />
              If you own a clothing store, you can request access to our B2B platform by emailing us at <a href="mailto:commerciale@blucashmere.eu" target="_blank" style={{ textDecoration: "none", color: "#88695f" }}><b>commerciale@blucashmere.eu</b></a> or calling <a href="tel:+390758038799" target="_blank" style={{ textDecoration: "none", color: "#88695f" }}><b>0039.075.80.38.799</b></a>.
            </Typography>
          </Paper>
          <TextField
            className={style.textField}
            error={loginError ? true : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formValues["email"]}
            onChange={onChangeForm}
          />
          <TextField
            className={style.textField}
            error={loginError ? true : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formValues["password"]}
            onChange={onChangeForm}
            onKeyPress={event => { if (event.key === "Enter") onLogin(); }}
          />
          {
            loginError ?
              (
                <Typography align="left" color="error" variant="caption" className={style.loginErrorMessage}>
                  * Email o Password non corretti.
                </Typography>
              ) : (
                <></>
              )
          }
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Ricordami"
            className={style.centerFormControlLabel}
          />
          <Button
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            className={style.submit}
            onClick={onLogin}
            size="large"
          >
            Accedi
          </Button>
          <Grid container>
            <Grid item xs={6}>
              <Typography className={style.passwordText} variant="body2" onClick={openModalForgotPassword}>
                Password Dimenticata?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {/* <Typography className={style.passwordText} variant="body2" align="right" onClick={() => window.location.pathname = "/register"}>
                Registrazione
              </Typography> */}
            </Grid>
          </Grid>
        </div>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
              Blu Cashmere
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
      <Modal
        open={modalForgotPassword}
        onClose={closeModalForgotPassword}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={style.modal}
      >
        <div className={style.modalBody}>
          <div className={style.modalTitle}>
            <Typography component="h1" variant="h5" align="center">
              Password Dimenticata? <span role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">🤔</span>
            </Typography>
          </div>
          {
            passwordResetEmailResponse === 1 ? (
              <>
                <div className={style.modalSubTitle}>
                  <Typography variant="body1">
                    È stata inviata una mail a <strong>{formValues["email"]}</strong> con un link per ripristinare la password.
                  </Typography>
                </div>
                <div className={style.modalFooter}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={closeModalForgotPassword}
                    className={style.modalSubmit}
                  >
                    Ho capito!
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={style.modalSubTitle}>
                  <Typography variant="body1">
                    Inserisci la tua Email per ripristinare la password.
                  </Typography>
                </div>
                <div className={style.modalContent}>
                  <TextField
                    className={style.textField}
                    error={passwordResetEmailError ? true : false}
                    helperText={passwordResetEmailError ? "* Email non corretta" : false}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="password-reset-email"
                    autoFocus
                    value={formValues["password-reset-email"]}
                    onChange={onChangeForm}
                  />
                </div>
                <div className={style.modalFooter}>
                  <Button
                    size="large"
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={onPasswordReset}
                    className={style.modalSubmit}
                  >
                    {
                      loadingPasswordReset === true ? (<CircularProgress className={style.loading} />) : ("conferma")
                    }
                  </Button>
                </div>
              </>
            )
          }
        </div>
      </Modal>
    </>
  );
};

export default Form;
