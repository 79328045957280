import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

// Style by status of order
const checkClassNameForStatus = (status, style) => {
  if (status === "In lavorazione") {
    return style.boxStatusInLavorazione;
  }
  if (status === "Confermato") {
    return style.boxStatusConfermato;
  }
  if (status === "Annullato") {
    return style.boxStatusAnnullato;
  }
  if (status === "Evaso") {
    return style.boxStatusEvaso;
  }
  if (status === "Ricevuto") {
    return style.boxStatusRicevuto;
  }
};

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  dataOrderDetail,
  dataOrder,
  downloadPDF,
  dataMe,
  onOpenModalEditRow,
  onCloseModalEditRow,
  openModalEditRow,
  editRowFormValues,
  onChangeEditRow,
  onOpenModalAddRow,
  onCloseModalAddRow,
  openModalAddRow,
  addRowFormValues,
  onChangeAddRow,
  onOpenModalDeleteRow,
  onCloseModalDeleteRow,
  openModalDeleteRow,
  rowToDelete,
  onDeleteRow,
  dataProduct,
  onEditOrderDetail,
  successEditDetail,
  onCloseSuccessEditDetail,
  dataAllProducts,
  dataProductForEditOrderDetail,
  loadingProductForEditOrderDetail,
  onAddRow,
  errorStock,
  onEditNote,
  openEditNote,
  setOpenEditNote,
  discountItem,
}) => {
  const style = Style();
  let countTotal = 0.0;
  let countQuantity = 0.0;

  console.log(dataOrder);
  console.log(dataOrderDetail);

  const [newNote, setNewNote] = useState(dataOrder.getOrderUserById.note);

  return (
    <>
      <div style={{ margin: "1rem 0.5rem" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => downloadPDF(dataOrder.getOrderUserById.id, dataOrder.getOrderUserById.vat_number)}
          style={{ marginBottom: "1rem" }}
          startIcon={<CloudDownloadIcon />}
        >
          PDF
        </Button>
        <Grid container xl={12} id="containerPDF">
          <Grid item xs={12} sm={7}>
            <Typography variant="h4" style={{ paddingBottom: "1rem" }}>
              Dettagli Ordine #{dataOrder.getOrderUserById.id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} >
            <div className={style.wrapperDataAndStatus}>
              <b>{dataOrder.getOrderUserById.time}</b>
              <div className={checkClassNameForStatus(dataOrder.getOrderUserById.status, style)} >
                {dataOrder.getOrderUserById.status}
              </div>
            </div>
          </Grid>
          <TableContainer>
            <Table className={style.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"><b>Codice</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Nome</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Immagine</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Colore</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Codice Colore</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Taglia</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Prezzo</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Quantita</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Sub Totale</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Totale</b></StyledTableCell>
                  <StyledTableCell align="center"><b>Azioni</b></StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody style={{background: "#FFFFFF"}}>
                {
                  dataOrderDetail.myOrders.map((item, index) => {
                    countTotal += item.total;
                    countQuantity += item.quantity;
                    return (
                      <StyledTableRow key={index}>
                        <TableCell align="center">{item.product_code}</TableCell>
                        <TableCell align="center"><Typography className={style.productName} >
                          {item.product_name}{item.is_preorder_item === 1 ? <span style={{ fontSize: "8px" }}><br />PREORDER</span> : ""}
                        </Typography></TableCell>
                        <TableCell align="center"><img src={item.product_image} width="60" height="70" alt={item.product_name} /></TableCell>
                        <TableCell align="center"><img src={item.color_image} width="70" height="70" alt={item.color_name} /></TableCell>
                        <TableCell align="center">{item.color_name}</TableCell>
                        <TableCell align="center"><div className={style.sizeBySizeGroupWrapper}>{item.size}</div></TableCell>
                        <TableCell align="center">€{item.product_price}</TableCell>
                        <TableCell align="center">{item.quantity}</TableCell>
                        <TableCell align="center">€{item.sub_total}</TableCell>
                        <TableCell align="center">€{item.total}</TableCell>
                        <TableCell align="center">
                          <EditIcon className={style.buttonEditUploadedImage} color="primary" style={{ margin: "0 5px", cursor: "pointer" }} onClick={() => onOpenModalEditRow(item)} />
                          {
                            dataOrderDetail.myOrders.length > 1 ? (
                              <CloseIcon className={style.buttonEditUploadedImage} color="primary" style={{ margin: "0 5px", cursor: "pointer" }} onClick={() => onOpenModalDeleteRow(item)} />
                            ) : (
                              <></>
                            )
                          }
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container xs={12} direction="column" alignItems="center" justify="center" style={{ margin: "1.5rem 0" }}>
            {dataOrder.getOrderUserById.status !== "Annullato" && (
              <Button
                type='submit'
                variant="contained"
                size="medium"
                color="primary"
                onClick={onOpenModalAddRow}
              >
                Aggiungi riga
              </Button>
            )}
          </Grid>
          <Paper elevation={3} className={style.paperOrderSummary}>
            <Typography variant="h4" color="primary" style={{ padding: "0.75rem" }}>
              Ordine #{dataOrder.getOrderUserById.id}
            </Typography>
            <Typography variant="body1" style={{ padding: "0.75rem" }}>
              <b>Data: </b>{dataOrder.getOrderUserById.time}<br />
              <b>Codice cliente: </b>{dataOrder.getOrderUserById.code}<br />
              <b>Partita Iva: </b>{dataOrder.getOrderUserById.vat_number}<br />
              <b>Email: </b>{dataOrder.getOrderUserById.email}<br />
              <b>Numero Pezzi: </b>{parseFloat(countQuantity)}<br />
              <b>Note: </b>{newNote}<br />
            </Typography>
            {openEditNote &&
              <TextField
                variant="outlined"
                type="text"
                id="note"
                name="note"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                size="small"
                multiline
                rows={5}
                fullWidth
                style={{ marginBottom: "16px" }}
              />
            }
            <Button
              variant="outlined"
              size="small"
              onClick={() => openEditNote ? (setOpenEditNote(!openEditNote), setNewNote(dataOrder.getOrderUserById.note)) : setOpenEditNote(!openEditNote)}>
              {openEditNote ? "Annulla" : "Modifica note"}
            </Button>
            {openEditNote &&
              <Button variant="contained" style={{ marginLeft: "12px" }} size="small" onClick={() => onEditNote(newNote)}>Salva</Button>
            }
            <Typography variant="h5" style={{ padding: "0.75rem" }}>
              <b>Totale: </b>€ {countTotal.toFixed(2)}
              {(dataOrder.getOrderUserById.vat_number_nationality !== 0 || dataOrder.getOrderUserById.total === dataOrder.getOrderUserById.sub_total) && <Typography variant="caption" component="span"> <b>(NO IVA)</b></Typography>}<br />
            </Typography>
          </Paper>
        </Grid>
      </div>
      <Modal
        open={openModalEditRow}
        onClose={onCloseModalEditRow}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalEditRow}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h5" align="center" gutterBottom >
              Modifica Prodotto <b>{editRowFormValues.product_code}</b> ({editRowFormValues.product_name})
            </Typography>
          </Grid>

          <FormControl variant="outlined" size="small" style={{ width: "100%", marginBottom: "1rem" }}>
            <InputLabel id="demo-simple-select-outlined-label">Codice colore</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              onChange={onChangeEditRow}
              value={editRowFormValues.color}
              name="color"
              label="Codice colore"
              required
            >
              {
                dataProductForEditOrderDetail && dataProductForEditOrderDetail.getProductForEditOrderDetail.colorFolder && dataProductForEditOrderDetail.getProductForEditOrderDetail.colorFolder.colors ? (
                  dataProductForEditOrderDetail.getProductForEditOrderDetail.colorFolder.colors.map((color, index) => (
                    <MenuItem value={color.id} key={`color_${index}`}>{color.hex}</MenuItem>
                  ))
                ) : (<MenuItem value="">Non ci sono colori</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">Taglia</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              onChange={onChangeEditRow}
              value={editRowFormValues.size}
              name="size"
              label="Taglia"
              required
            >
              {
                dataProductForEditOrderDetail && dataProductForEditOrderDetail.getProductForEditOrderDetail.groupSize && dataProductForEditOrderDetail.getProductForEditOrderDetail.groupSize.sizes ? (
                  dataProductForEditOrderDetail.getProductForEditOrderDetail.groupSize.sizes.map((size, index) => (
                    <MenuItem value={size.id} key={`size_${index}`}>{size.name}</MenuItem>
                  ))
                ) : (<MenuItem value="">Non ci sono taglie</MenuItem>)
              }
            </Select>
          </FormControl>
          <TextField
            name="quantity"
            value={editRowFormValues.quantity}
            onChange={onChangeEditRow}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Quantità"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            required
          />
          <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
            {errorStock && (<Grid item xs={12}><Typography variant="caption" color="error" fullWidth>- {errorStock}</Typography></Grid>)}
            <Grid item >
              <Button
                type='submit'
                onClick={onCloseModalEditRow}
                variant="contained"
                size="medium"
                color="primary"
              >
                Annulla
              </Button>
            </Grid>
            <Grid item >
              <Button
                type='submit'
                onClick={onEditOrderDetail}
                variant="contained"
                size="medium"
                color="primary"
              >
                Conferma
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openModalDeleteRow}
        onClose={onCloseModalDeleteRow}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalDeleteRow}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h5" color="error" align="center" gutterBottom >
              Elimina prodotto <b>{rowToDelete.product_code}</b> ({rowToDelete.product_name}) dall'ordine?
            </Typography>
          </Grid>
          <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item >
              <Button
                type='submit'
                onClick={onCloseModalDeleteRow}
                variant="contained"
                size="medium"
                color="primary"
              >
                Annulla
              </Button>
            </Grid>
            <Grid item >
              <Button
                type='submit'
                onClick={onDeleteRow}
                variant="contained"
                size="medium"
                color="primary"
              >
                Conferma
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openModalAddRow}
        onClose={onCloseModalAddRow}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalAddRow}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h5" align="center" gutterBottom >
              Aggiungi nuovo prodotto
            </Typography>
          </Grid>
          <FormControl variant="outlined" size="small" style={{ width: "100%", marginBottom: "1rem" }}>
            <InputLabel id="demo-simple-select-outlined-label">Prodotto</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              onChange={onChangeAddRow}
              value={addRowFormValues.color_name}
              name="product"
              label="Prodotto"
              required
            >
              {
                dataAllProducts && dataAllProducts.getAllProductsForEditOrderDetail && (
                  dataAllProducts.getAllProductsForEditOrderDetail.filter(item => item.stock === 0).map((product, index) => (
                    <MenuItem value={product.id} key={`product_${index}`}><b>{product.code}</b> &nbsp; (€ {product.price})  </MenuItem>
                  ))
                )
              }
            </Select>
          </FormControl>
          {
            loadingProductForEditOrderDetail ? (<p>loading</p>) : (
              <>
                <FormControl variant="outlined" size="small" style={{ width: "100%", marginBottom: "1rem" }}>
                  <InputLabel id="demo-simple-select-outlined-label">Colore</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    onChange={onChangeAddRow}
                    value={addRowFormValues.color}
                    name="color"
                    label="Colore"
                    required
                  >
                    {
                      dataProductForEditOrderDetail && dataProductForEditOrderDetail.getProductForEditOrderDetail.colorFolder && dataProductForEditOrderDetail.getProductForEditOrderDetail.colorFolder.colors ? (
                        dataProductForEditOrderDetail.getProductForEditOrderDetail.colorFolder.colors.map((color, index) => (
                          <MenuItem value={color.id} key={`color_${index}`}>{color.hex}</MenuItem>
                        ))
                      ) : (<MenuItem value="">Non ci sono colori</MenuItem>)
                    }
                  </Select>
                </FormControl>
                <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-outlined-label">Taglia</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    onChange={onChangeAddRow}
                    value={addRowFormValues.size}
                    name="size"
                    label="Taglia"
                    required
                  >
                    {
                      dataProductForEditOrderDetail && dataProductForEditOrderDetail.getProductForEditOrderDetail.groupSize && dataProductForEditOrderDetail.getProductForEditOrderDetail.groupSize.sizes ? (
                        dataProductForEditOrderDetail.getProductForEditOrderDetail.groupSize.sizes.map((size, index) => (
                          <MenuItem value={size.id} key={`size_${index}`}>{size.name}</MenuItem>
                        ))
                      ) : (<MenuItem value="">Non ci sono taglie</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </>
            )
          }
          <TextField
            name="quantity"
            value={addRowFormValues.quantity}
            onChange={onChangeAddRow}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Quantità"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1 } }}
            required
          />
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ color: "green" }} align="center"> {discountItem && "ARTICOLO IN SCONTO"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="h6" align="center" gutterBottom >
                Sub Totale
              </Typography>
              {
                addRowFormValues && addRowFormValues.sub_total ? (
                  <Typography variant="body2" align="center" gutterBottom >
                    € {Number(addRowFormValues.sub_total).toFixed(2)}
                  </Typography>
                ) : (<Typography variant="body2" align="center" gutterBottom >0</Typography>)
              }
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Typography variant="h6" align="center" gutterBottom >
                Totalee
              </Typography>
              {
                addRowFormValues && addRowFormValues.total ? (
                  <Typography variant="body2" align="center" gutterBottom >
                    € {dataOrder.getOrderUserById.vat_number_nationality === 0 ? Number(addRowFormValues.total).toFixed(2) : Number(addRowFormValues.sub_total).toFixed(2)}
                  </Typography>
                ) : (<Typography variant="body2" align="center" gutterBottom >0</Typography>)
              }
            </Grid>
          </Grid>
          <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
            {errorStock && (<Grid item xs={12}><Typography variant="caption" color="error" fullWidth>- {errorStock}</Typography></Grid>)}
            <Grid item >
              <Button
                type='submit'
                onClick={onCloseModalAddRow}
                variant="contained"
                size="medium"
                color="primary"
              >
                Annulla
              </Button>
            </Grid>
            <Grid item >
              <Button
                onClick={onAddRow}
                type='submit'
                variant="contained"
                size="medium"
                color="primary"
                disabled={!addRowFormValues.product || !addRowFormValues.color || !addRowFormValues.size || !addRowFormValues.quantity || !addRowFormValues.sub_total || !addRowFormValues.total}
              >
                Conferma
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successEditDetail}
        onClose={onCloseSuccessEditDetail}
        autoHideDuration={2000}
      //key={{vertical: "top", horizontal: "center"}}
      >
        <Alert onClose={onCloseSuccessEditDetail} severity="success" elevation={6} variant="filled">
          Ordine modificato
        </Alert>
      </Snackbar>
    </>
  );
};

export default Layout;
