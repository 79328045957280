import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  header: {
    flexGrow: 1,
    marginBottom: "1rem",
  },
  appBar:{
    position: "relative",
    // backgroundColor: "#D6B290",
    backgroundColor: "#e8e4e5"
  },
  menuButton: {
    marginRight: "1rem",
  },
  titleText: {
    // fontFamily: "Cormorant Garamond, serif",
    fontWeight: 600,
    display: "inline-block",
    marginLeft: "0.2rem",
    color: theme.palette.primary.main,
  },
  title: {
    flexGrow: 1,
  },
  titleImage: {
    verticalAlign: "middle",
    width: "auto",
    height: "50px",
    margin: "10px 0",
    cursor: "pointer",
  },
  titleImageNoWrite: {
    verticalAlign: "middle",
    height: "35px",
    cursor: "pointer",
  },
  badge: {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    minWidth: "unset",
    width: "15px",
    height: "15px",
    lineHeight: 0
  },
  micWithe: {
  },
  micGreen: {
    color: "var(--mate-green)",
    animationName: "$blinker",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount:"infinite",
  },
  "@keyframes blinker": {
    from: {opacity: 1},
    to: {opacity: 0.3},
  },
  paperModal: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 4, 3),
    marginTop: theme.spacing(7),
    margin: "auto",
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));
