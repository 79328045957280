import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: "auto",
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  wrapperDataAndStatus: {
    float: "right",
    marginRight: "0.75rem",
    [theme.breakpoints.down('xs')]: {
      float: "unset",
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  boxStatusInLavorazione: {
    background: "#ffeb3b",
    borderRadius: "5px",
    padding: "0.5rem 0.75rem",
    margin: theme.spacing(0, 0, 0, 1),
    display: "inline-block",
  },
  boxStatusAnnullato: {
    background: "#f44336",
    borderRadius: "5px",
    padding: "0.5rem 0.75rem",
    margin: theme.spacing(0, 0, 0, 1),
    display: "inline-block",
  },
  boxStatusConfermato: {
    background: "#2189f5",
    borderRadius: "5px",
    padding: "0.5rem 0.75rem",
    margin: theme.spacing(0, 0, 0, 1),
    display: "inline-block",
  },
  boxStatusRicevuto: {
    background: "#64dd17",
    borderRadius: "5px",
    padding: "0.5rem 0.75rem",
    margin: theme.spacing(0, 0, 0, 1),
    display: "inline-block",
  },
  boxStatusEvaso: {
    background: "#ef2de9",
    borderRadius: "5px",
    padding: "0.5rem 0.75rem",
    margin: theme.spacing(0, 0, 0, 1),
    display: "inline-block",
  },
  productImage: {
    width: "70px",
    height: "70px",
    margin: "auto",
  },
  colorImage: {
    margin: "auto",
  },
  paperOrderSummary: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    width: "100%",
  },
  sizeBySizeGroupWrapper: {
    background: "#ffffff",
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: "600",
    padding: "0.25rem 0.5rem",
    textAlign: "center",
    minWidth: "50px",
  },
}));
