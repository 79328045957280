import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: "auto",
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  buttonEditUploadedImage: {
    position: "absolute",
    right: "-20px",
    bottom: "-12px",
    background:  theme.palette.primary.main,
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    color: "white",
    padding: "0.5rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeIconGalleryImage: {
    position: "relative",
    right: "20px",
    bottom: "-20px",
    background: "red",
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    color: "white",
    padding: "0.5rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchField: {
    width: "auto",
    marginBottom: "1rem",
    float: "right",
    [theme.breakpoints.down("xs")]: {
      float: "left",
      width: "100%",
    },
  },

  selectAllWrapper: {
    paddingLeft: "1.75rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
      marginBottom: "1rem",
      verticalAlign: "top",
    },
  },
  textFieldAddNewFolder: {
    marginRight: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem 0 0",
      width: "100%",
    },
  },
  buttonAddNew: {
    marginTop: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  selectColorFolder: {
    marginTop:"16.5px",
    width: "220px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "2rem",
    },
  },
  selectColorSeasonFolder: {
    marginTop:"16.5px",
    width: "220px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "2rem",
      marginRight: "0",
    },
  },
  errorHexDuplicated: {
    color: "red",
  },
  avatarColor:{
    width: "70px",
    height: "70px",
    margin: "auto",
  },
}));
