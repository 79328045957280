import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import BackspaceIcon from "@material-ui/icons/Backspace";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Style from "./style.js";
import { withStyles } from "@material-ui/core/styles";
import Logo from "../../../../assets/images/CashmereCompanyLogo.png"


const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "green",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const YellowCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "orange",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const VioletCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "violet",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const productsNotFound = (style, onGoBack) => (
  <div className={style.paperNotFoundContent}>
    <p className={style.paperNotFoundText}>Opsss...</p>
    <span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">🧐</span>
    <p style={{ marginBottom: "1.5rem" }}>Non ci sono prodotti per questa ricerca!</p>
    <Button
      className={style.resetFilterButton}
      size="small"
      variant="contained"
      color="secondary"
      onClick={onGoBack}
      startIcon={<BackspaceIcon />}
    >Torna indietro</Button>
  </div>
);

const productLoading = (style) => (
  <div className={style.productsSkeleton}>
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
    <Skeleton className={style.skeleton} variant="rect" height={419.5} />
  </div>
);

const Layout = ({
  dataPreorderSeasonProducts,
  onGoBack,
  onFavorite,
  userFavorites,
  onViewProduct,
  onScrollToTop,
  dataMe,
}) => {
  const style = Style();

  const [hidePrice, setHidePrice] = useState(localStorage.getItem("hidePricingAgent"));

  window.addEventListener('storage', () => {
    setHidePrice(!!localStorage.getItem("hidePricingAgent"));
  })

  return (
    <Grid container spacing={3} className={style.shop}>
      {!dataMe.me.agent.preorder ?
        <>Non hai i permessi per visualizzare questa pagina</>
        :
        <div className={style.productsContainer}>
          <Typography align="center" variant="h2" style={{ marginBottom: "30px" }}>PREORDER</Typography>
          {
            dataPreorderSeasonProducts && dataPreorderSeasonProducts.getPreorderSeasonProducts.length > 0 ? (
              <>
                <div className={style.productsList}>
                  {
                    dataPreorderSeasonProducts.getPreorderSeasonProducts.map((item, index) => (
                      <Paper key={index + "categoryProducts"} elevation={3} className={style.productCard}>
                        <img src={item.image} className={style.productImage} alt={item.name} onClick={() => onViewProduct(item.id)} />
                        <img
                          src={Logo}
                          alt="Logo"
                          style={{
                            width: "60px",
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                            zIndex: "99",
                          }}
                        />
                        <div style={{ position: "absolute", top: "10px", right: "10px", display: "flex", flexDirection: "column", gap: "12px", alignItems: "end" }}>
                          {item.stock ? (
                            <div className={style.stockWrapper}>
                              <span className={style.stockText}>ESAURITO</span>
                            </div>
                          ) : (
                            <>
                              {item.bestseller === 1 && (
                                <div className={style.bestsellerWrapper}>
                                  <span className={style.bestsellerText}>MADE IN ITALY</span>
                                </div>
                              )}

                              {item.luxury === 1 && (
                                <div className={style.bestsellerWrapper}>
                                  <span className={style.bestsellerText}>NOOS</span>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <p className={style.productName}>{item.name} {item.fineness ? item.fineness.name : null}</p>
                        <div className={style.productNameContainer}>
                          <div className={style.productInfo}>
                            <p className={style.productCode}>Cod: {item.code}</p>
                          </div>
                          {!hidePrice && (
                            item.sale && item.sale > 0 ?
                              (<><p className={style.productPrice}>IN SALDO </p><div><p style={{ textDecoration: "line-through", margin: 0, marginRight: "1.2px" }}>€{item.price} </p> <b> €{item.sale}</b></div></>)
                              : (item.percentage_discount && item.percentage_discount > 0 ?
                                (<><p className={style.productPrice}>SCONTO {item.percentage_discount}% </p><div><p style={{ textDecoration: "line-through", margin: 0, marginRight: "1.2px" }}>€{item.price} </p> <b> €{(item.price - item.price * (item.percentage_discount * 0.01)).toFixed(2)}</b></div></>)
                                : (item.discountColors ? (<><p className={style.productPrice}>COLORI IN SCONTO </p><div><p>€{item.price}</p></div></>) : <p>€{item.price}</p>))
                          )}
                        </div>
                        <div className={style.productFooter}>
                          <FormControlLabel
                            className={style.productHeart}
                            control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite style={{ color: "#ff5b60" }} />} onClick={() => onFavorite(item.id)} checked={userFavorites.includes(item.id) ? true : false} />}
                          />
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => onViewProduct(item.id)}
                            startIcon={<VisibilityIcon />}
                          >Visualizza</Button>
                        </div>
                      </Paper>
                    ))
                  }
                </div>
                <div className={style.scrollEndMessage}>
                  <p className={style.scrollEndMessageText}>Non ci sono altri prodotti da visualizzare</p>
                  <p><span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">👀</span></p>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={onScrollToTop}
                    startIcon={<ArrowUpwardIcon />}
                  >Torna in cima</Button>
                </div>
              </>
            ) : (productsNotFound(style, onGoBack))
          }
        </div>
      }
    </Grid>
  );
};

export default Layout;
