import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from "@material-ui/core/Divider";
import Regioni from "../../../../data/regioni.json";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  dataUsers,
  dataAgents,
  onRemoveUser,
  onChangeEditUser,
  editUserFormValues,
  onUpdateUser,
  openModalEditUser,
  onOpenModalEditUser,
  onCloseModalEditUser,
  disabledSaveEditUser,
  updateUserError,
  openModalConfirmDeleteUser,
  onCloseConfirmDeleteUser,
  onOpenConfirmDeleteUser,
  idUserToRemove,
  dataPriceLists,
  openModalAddNewUser,
  onCloseModalAddNewUser,
  onOpenModalAddNewUser,
  addNewUserformValues,
  onChangeFormAddNewUser,
  onChangeRegion,
  onAddNewUser,
  search,
  onChangeSearch,
  loadingRemoveUser,
  loadingEditUser,
  loadingAddNewUser,
  disabledSaveNewUser,
  registrationErrorConfirm,
  onChangeMassiveDelete,
  checkboxMassiveDelete,
  deleteAllSelected,
  allDeleteChecked,
  openModalConfirmDeleteAllUser,
  onCloseModalConfirmDeleteAllUser,
  onOpenModalConfirmDeleteAllUser,
  loadingRemoveAllUser,
  onChangeVatNumberNationality,
  vatNumberNationality,
  onChangeSort,
  sort,
  errorCreateUser,
}) => {
  const style = Style();
  const regioni = Regioni;

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom: "0" }}>
          <Typography variant="h4">
            Utenti
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} style={{ paddingBottom: "0", paddingTop: "0" }}>
          <Button
            className={style.buttonAddNew}
            style={{ marginBottom: "1.5rem", marginTop: "1rem" }}
            onClick={() => onOpenModalAddNewUser()}
            variant="contained"
            size="medium"
            color="primary"
          >
            Aggiungi Utente
          </Button>
          <Button
            className={style.buttonAddNew}
            style={{ marginBottom: "1.5rem", marginTop: "1rem" }}
            onClick={() => window.location = "/admin/users-edit"}
            variant="contained"
            size="medium"
            color="primary"
          >
            Modifica Rapida
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: "0", paddingTop: "0" }}>
          <TextField
            className={style.searchField}
            variant="outlined"
            margin="normal"
            id="search"
            label="Cerca Utente"
            size="small"
            value={search}
            onChange={onChangeSearch}
            defaultValue={""}
          />
        </Grid>
        <Grid item xs={12}>
          {
            dataUsers && dataUsers.sortUsersAdmin.length > 0 ? (
              <>
                <FormControl variant="outlined" margin="normal" fullWidth size="small" className={style.selectSort}>
                  <InputLabel>Ordina per</InputLabel>
                  <Select
                    name="list_type_0"
                    label="Listino Estivo"
                    onChange={onChangeSort}
                    value={sort}
                    size="small"
                  >
                    <div style={{ textAlign: "center" }}><b>Ascendente</b></div>
                    <MenuItem value="id-asc">ID <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="business_name-asc">Ragione Sociale <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="vat_number-asc">Partita Iva <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="email-asc">Email <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="code-asc">Codice <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="region-asc">Regione <ArrowUpwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>

                    <Divider />
                    <div style={{ textAlign: "center" }}><b>Discendente</b></div>
                    <MenuItem value="id-desc">ID <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="business_name-desc">Ragione Sociale <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="vat_number-desc">Partita Iva <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="email-desc">Email <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="code-desc">Codice <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                    <MenuItem value="region-desc">Regione <ArrowDownwardIcon style={{ fontSize: "1.1rem" }} /></MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  className={style.selectAllWrapper}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={onChangeMassiveDelete}
                      name="all"
                      checked={allDeleteChecked}
                    />
                  }
                  label="Seleziona tutto"
                />
                <Button
                  variant="contained"
                  size="small"
                  disabled={checkboxMassiveDelete.length > 0 ? false : true}
                  onClick={onOpenModalConfirmDeleteAllUser}
                >
                  Elimina selezionati
                </Button>
                <TableContainer component={Paper}>
                  <Table className={style.table} size="small">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="left"></StyledTableCell>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell align="center">Ragione Sociale</StyledTableCell>
                        <StyledTableCell align="center">Partita IVA</StyledTableCell>
                        <StyledTableCell align="center">Email</StyledTableCell>
                        <StyledTableCell align="center">Codice</StyledTableCell>
                        <StyledTableCell align="center">Regione</StyledTableCell>
                        <StyledTableCell align="center">Listino Estivo</StyledTableCell>
                        <StyledTableCell align="center">Listino Invernale</StyledTableCell>
                        <StyledTableCell align="center">Agente</StyledTableCell>
                        <StyledTableCell align="center">Registrazione</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {
                        search ? (
                          dataUsers.sortUsersAdmin.filter(item => item.email.toLowerCase().includes(search.toLowerCase()) || item.code?.toLowerCase().includes(search.toLowerCase()) || item.vat_number.toLowerCase().includes(search.toLowerCase()) || (item.agent && item.agent.nominative.toLowerCase().includes(search.toLowerCase())) || item.business_name?.includes(search.toLowerCase())).map((item, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="left" style={{ width: "50px" }}>
                                <Checkbox
                                  color="primary"
                                  name={item.id}
                                  onChange={onChangeMassiveDelete}
                                  checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">{index + 1}</StyledTableCell>
                              <StyledTableCell align="left">{item.business_name}</StyledTableCell>
                              <StyledTableCell align="center">{item.vat_number}</StyledTableCell>
                              <StyledTableCell align="center">{item.email}</StyledTableCell>
                              <StyledTableCell align="center">{item.code}</StyledTableCell>
                              <StyledTableCell align="center">{item.vat_number_nationality === 0 ? item.region : "Estero"}</StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_0) !== -1 ?
                                    dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_0)].name
                                    :
                                    "No Listino"
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_1) !== -1 ?
                                    dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_1)].name
                                    :
                                    "No Listino"
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">{item.agent === null ? "No Agente" : item.agent.nominative}</StyledTableCell>
                              <StyledTableCell align="center">{item.created_at.substr(0, 10)}</StyledTableCell>
                              <StyledTableCell align="center">
                                <IconButton onClick={() => onOpenModalEditUser(item)}>
                                  <EditIcon color="primary" fontSize="default" />
                                </IconButton>
                                <IconButton onClick={() => onOpenConfirmDeleteUser(item.id)}>
                                  <DeleteIcon color="error" fontSize="default" />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) :
                          dataUsers.sortUsersAdmin.map((item, index) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="left" style={{ width: "50px" }}>
                                  <Checkbox
                                    color="primary"
                                    name={item.id}
                                    onChange={onChangeMassiveDelete}
                                    checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                <StyledTableCell align="center">{item.business_name}</StyledTableCell>
                                <StyledTableCell align="center">{item.vat_number}</StyledTableCell>
                                <StyledTableCell align="center">{item.email}</StyledTableCell>
                                <StyledTableCell align="center">{item.code}</StyledTableCell>
                                <StyledTableCell align="center">{item.vat_number_nationality === 0 ? item.region : "Estero"}</StyledTableCell>
                                <StyledTableCell align="center">
                                  {
                                    dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_0) !== -1 ?
                                      dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_0)].name
                                      :
                                      "No Listino"
                                  }
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {
                                    dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_1) !== -1 ?
                                      dataPriceLists.priceLists[dataPriceLists.priceLists.findIndex(listItem => listItem.id === "" + item.list_type_1)].name
                                      :
                                      "No Listino"
                                  }
                                </StyledTableCell>
                                <StyledTableCell align="center">{item.agent === null || item.agent === "" || item.agent === undefined ? "No Agente" : item.agent.nominative}</StyledTableCell>
                                <StyledTableCell align="center">{item.created_at.substr(0, 10)}</StyledTableCell>
                                <StyledTableCell align="center">
                                  <IconButton onClick={() => onOpenModalEditUser(item)}>
                                    <EditIcon color="primary" fontSize="default" />
                                  </IconButton>
                                  <IconButton onClick={() => onOpenConfirmDeleteUser(item.id)}>
                                    <DeleteIcon color="error" fontSize="default" />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (<p style={{ margin: "0" }}>Non ci sono utenti</p>)
          }
        </Grid>
      </Grid>
      <Modal
        open={openModalEditUser}
        onClose={onCloseModalEditUser}
        className={style.modal}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalEditUser}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Modifica utente
          </Typography>
          <TextField
            error={updateUserError}
            name="business_name"
            onChange={onChangeEditUser}
            value={editUserFormValues.business_name}
            //error={errorEditUser || vatNumberError ? true : false}
            //helperText={vatNumberError ? "La Partita IVA inserita non è corretta" : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Ragione Sociale"
          />
          <TextField
            error={updateUserError}
            name="vat_number"
            onChange={onChangeEditUser}
            value={editUserFormValues.vat_number}
            //error={errorEditUser || vatNumberError ? true : false}
            //helperText={vatNumberError ? "La Partita IVA inserita non è corretta" : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Partita IVA"
          />
          <TextField
            error={updateUserError}
            name="email"
            onChange={onChangeEditUser}
            value={editUserFormValues.email}
            //error={errorEditUser || vatNumberError ? true : false}
            //helperText={vatNumberError ? "La Partita IVA inserita non è corretta" : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
          />
          <TextField
            error={updateUserError}
            name="code"
            onChange={onChangeEditUser}
            value={editUserFormValues.code}
            //error={errorEditUser || vatNumberError ? true : false}
            //helperText={vatNumberError ? "La Partita IVA inserita non è corretta" : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Code"
          />
          {
            editUserFormValues.vat_number_nationality === 0 ? (
              <FormControl variant="outlined" margin="normal" fullWidth >
                <InputLabel>Regione</InputLabel>
                <Select
                  error={updateUserError}
                  name="region"
                  value={editUserFormValues.region}
                  onChange={onChangeEditUser}
                  label="Regione"
                //error={errorEditUser ? true : false}
                >
                  {
                    regioni.map(item => {
                      return (
                        <MenuItem value={item.regione}>{item.regione}</MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            ) : (
              <></>
            )
          }
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Estivo</InputLabel>
            <Select
              error={updateUserError}
              onChange={onChangeEditUser}
              value={editUserFormValues.list_type_0}
              name="list_type_0"
              label="Listino Estivo"
              required
            >
              <MenuItem value={null}><em>Nessuno</em></MenuItem>
              {
                dataPriceLists.priceLists.filter(element => element.type === 0).map((item) => {
                  return (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Invernale</InputLabel>
            <Select
              error={updateUserError}
              onChange={onChangeEditUser}
              value={editUserFormValues.list_type_1}
              name="list_type_1"
              label="Listino Invernale"
              required
            >
              <MenuItem value={null}><em>Nessuno</em></MenuItem>
              {
                dataPriceLists.priceLists.filter(element => element.type === 1).map((item) => {
                  return (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          {
            dataAgents ? (
              <FormControl variant="outlined" margin="normal" fullWidth >
                <InputLabel>Agente</InputLabel>
                <Select
                  name="agent"
                  value={editUserFormValues["agent"]}
                  onChange={onChangeEditUser}
                  label="Agente"
                >
                  {
                    dataAgents && dataAgents.agents.length > 0 ? (
                      dataAgents.agents.map((agent, index) => (
                        <MenuItem key={`agent_${index}`} value={agent.id}>{agent.nominative}</MenuItem>
                      ))

                    ) : null
                  }
                </Select>
              </FormControl>
            ) : null
          }


          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={editUserFormValues.password}
              onChange={onChangeEditUser}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {
            updateUserError ? (
              <>
                <Typography variant="caption" color="error">
                  Inserire tutti i campi e verificare la correttezza<br />
                  - Partita IVA corretta e non già registrata<br />
                  - Codice Cliente inserito e non già esistente<br />
                  - Email valida e non già registrata<br />
                </Typography>
              </>
            ) : (
              <></>
            )
          }
          {
            loadingEditUser ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    onClick={onCloseModalEditUser}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    onClick={onUpdateUser}
                    disabled={disabledSaveEditUser}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteUser}
        onClose={onCloseConfirmDeleteUser}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseConfirmDeleteUser}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare questo utente?
            </Typography>
          </Grid>
          {
            loadingRemoveUser ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseConfirmDeleteUser}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={() => onRemoveUser(idUserToRemove)}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalAddNewUser}
        onClose={onCloseModalAddNewUser}
        className={style.modal}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalAddNewUser}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Aggiungi nuovo utente
          </Typography>
          <Grid container xs={12}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={vatNumberNationality === 0 ? true : false}
                    onChange={onChangeVatNumberNationality}
                    name="vatNumberIt"
                    color="primary"
                    value={0}
                  />
                }
                label={<span style={{ fontWeight: "600" }}>Italia</span>}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={vatNumberNationality === 1 ? true : false}
                    onChange={onChangeVatNumberNationality}
                    name="vatNumberEx"
                    color="primary"
                    value={1}
                  />
                }
                label={<span style={{ fontWeight: "600" }}>Estero</span>}
              />
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={registrationErrorConfirm}
            id="businessName"
            label="Ragione Sociale"
            name="businessName"
            value={addNewUserformValues["businessName"]}
            onChange={onChangeFormAddNewUser}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={registrationErrorConfirm}
            id="vatNumber"
            label="Partita IVA"
            name="vatNumber"
            value={addNewUserformValues["vatNumber"]}
            onChange={onChangeFormAddNewUser}
          />
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={registrationErrorConfirm}
            name="email"
            label="Email"
            type="email"
            id="email"
            value={addNewUserformValues["email"]}
            onChange={onChangeFormAddNewUser}
          />
          <TextField
            className={style.textField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={registrationErrorConfirm}
            name="code"
            label="Code"
            type="code"
            id="code"
            value={addNewUserformValues["code"]}
            onChange={onChangeFormAddNewUser}
          />
          {
            vatNumberNationality === 0 ? (
              <FormControl variant="outlined" margin="normal" fullWidth >
                <InputLabel >Regione</InputLabel>
                <Select
                  name="region"
                  value={addNewUserformValues["region"]}
                  onChange={onChangeRegion}
                  error={registrationErrorConfirm}
                  label="Regione"
                  //error={registerError ? true : false}
                  required
                >
                  {
                    regioni.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.regione}>{item.regione}</MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            ) : (
              <></>
            )
          }
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Estivo</InputLabel>
            <Select
              onChange={onChangeFormAddNewUser}
              value={addNewUserformValues["list_type_0"]}
              name="list_type_0"
              error={registrationErrorConfirm}
              label="Listino Estivo"
              required
            >
              <MenuItem value={null}><em>Nessuno</em></MenuItem>
              {
                dataPriceLists.priceLists.filter(element => element.type === 0).map((item) => {
                  return (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Listino Invernale</InputLabel>
            <Select
              onChange={onChangeFormAddNewUser}
              value={addNewUserformValues["list_type_1"]}
              name="list_type_1"
              error={registrationErrorConfirm}
              label="Listino Invernale"
              required
            >
              <MenuItem value={null}><em>Nessuno</em></MenuItem>
              {
                dataPriceLists.priceLists.filter(element => element.type === 1).map((item) => {
                  return (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          {
            dataAgents && dataAgents.agents.length > 0 ? (
              <FormControl variant="outlined" margin="normal" fullWidth >
                <InputLabel>Agente</InputLabel>
                <Select
                  name="agent"
                  value={addNewUserformValues["agent"]}
                  onChange={onChangeFormAddNewUser}
                  label="Agente"
                >
                  {
                    dataAgents && dataAgents.agents.length > 0 ? (
                      dataAgents.agents
                        .slice()
                        .sort((a, b) => a.nominative.localeCompare(b.nominative))
                        .map((agent, index) => (
                          <MenuItem key={`agent_${index}`} value={agent.id}>{agent.nominative}</MenuItem>
                        ))

                    ) : ("non ci sono agenti")
                  }
                </Select>
              </FormControl>
            ) : null
          }
          <TextField
            className={style.textField}
            error={registrationErrorConfirm}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            defaultValue={addNewUserformValues["password"]}
            value={addNewUserformValues["password"]}
            onChange={onChangeFormAddNewUser}
          />
          <TextField
            className={style.textField}
            error={registrationErrorConfirm}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label="Conferma Password"
            type="password"
            id="passwordConfirmation"
            value={addNewUserformValues["passwordConfirmation"]}
            onChange={onChangeFormAddNewUser}
          />
          {errorCreateUser &&
            Object.keys(errorCreateUser).map((key, index) => (
              <Typography variant="caption" color="error" key={index}>
                {key}: {errorCreateUser[key]}
              </Typography>
            ))
          }
          {
            registrationErrorConfirm ? (
              <Typography variant="caption" color="error">
                Inserire tutti i campi e verificare la correttezza<br />
                - Partita IVA corretta e non già registrata<br />
                - Codice Cliente inserito e non già esistente<br />
                - Email valida e non già registrata<br />
                - Password e verifica password inserite correttamente<br />
              </Typography>
            ) : (
              <></>
            )
          }
          {
            loadingAddNewUser ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type="submit"
                    onClick={onCloseModalAddNewUser}
                    size="large"
                    color="primary"
                    variant="contained"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    onClick={onAddNewUser}
                    size="large"
                    color="primary"
                    variant="contained"
                    disabled={disabledSaveNewUser}
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteAllUser}
        onClose={onCloseModalConfirmDeleteAllUser}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalConfirmDeleteAllUser}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare <b>{checkboxMassiveDelete.length}</b> {checkboxMassiveDelete.length === 0 ? "utente " : "utente "}?
            </Typography>
          </Grid>
          {
            loadingRemoveAllUser ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseModalConfirmDeleteAllUser}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={deleteAllSelected}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
