import React, {useRef, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";
import {DropzoneDialog} from "material-ui-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import Style from "./style.js";
import MultiSelect from "../../../../components/multiSelect";

// Table responsive
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Layout = ({
  dataColors,
  onOpenModalAddNewColor,
  onCloseModalAddColor,
  addNewColorFormValues,
  onChangeAddColorFormValues,
  onSaveNewColor,
  openDropzone,
  onOpenDropzone,
  onCloseDropzone,
  onSaveImage,
  fileColorImage,
  deleteImageUploadedToAddImage,
  onRemoveColor,
  openModalConfirmDeleteColor,
  onCloseConfirmDeleteColor,
  onOpenConfirmDeleteColor,
  colorToDelete,
  openModalUpdateColor,
  onOpenUpdateColor,
  onCloseUpdateColor,
  colorToUpdate,
  onChangeUpdateColorFormValues,
  loadingAddNewColor,
  onSaveUpdateImage,
  onSaveUpdateColor,
  errorAddNewColor,
  errorUpdateColor,
  loadingUpdateColor,
  loadingRemoveColor,
  search,
  onChangeSearch,
  idColorImageUpdated,
  onChangeMassiveDelete,
  checkboxMassiveDelete,
  deleteAllSelected,
  allDeleteChecked,
  openModalConfirmDeleteAllColor,
  onCloseModalConfirmDeleteAllColor,
  onOpenModalConfirmDeleteAllColor,
  loadingRemoveAllColor,
  addNewColorFolderFormValues,
  onChangeAddColorFolderFormValues,
  errorAddNewColorFolder,
  onAddColorFolder,
  dataColorsFolder,
  onChangeFolder,
  colorFolder,
  editColorFolderById,
  errorEditColorFolder,
  openModalEditColorFolder,
  onOpenModalEditColorFolder,
  onCloseModalEditColorFolder,
  editColorFolderFormValues,
  onChangeEditColorFolderFormValues,
  openModalConfirmDeleteColorFolder,
  onOpenModalConfirmDeleteColorFolder,
  onCloseModalConfirmDeleteColorFolder,
  deleteColorFolderById,
  successDeleteFolder,
  errorMessageAddNewColor,
  onRemoveMessageAddNewColor,
  onChangeFolderSeason,
  colorFolderSeason,
  dataSeasons,
  loadingSeasons,
  errorSeasons,
}) => {
  const style = Style();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingBottom:"0" }}>
          <Typography variant="h4">
            Cartelle colori
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ paddingBottom:"0", paddingTop:"0"  }}>
          <TextField
            className={style.textFieldAddNewFolder}
            onChange={onChangeAddColorFolderFormValues}
            value={addNewColorFolderFormValues.colorFolder}
            name="colorFolder"
            label="Nuova Cartella"
            variant="outlined"
            size="small"
            margin="normal"
            error={errorAddNewColorFolder}
          />
          <Button
            className={style.buttonAddNew}
            variant="contained"
            size="medium"
            color="primary"
            onClick={onAddColorFolder}
            disabled={addNewColorFolderFormValues.colorFolder === undefined || addNewColorFolderFormValues.colorFolder === "" ? true : false}
          >
            Aggiungi
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ justifyContent: "flex-end", display: "flex", paddingBottom:"0", paddingTop:"0" }}>
          <FormControl variant="outlined" size="small" className={style.selectColorSeasonFolder} >
            <InputLabel id="demo-simple-select-outlined-label">Seleziona Stagione</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={onChangeFolderSeason}
              value={colorFolderSeason}
              name="folderSeason"
              label="Stagine"
              size="small"
            >
              <MenuItem value={null}><em>Nessuno</em></MenuItem>
              {dataSeasons?.seasons.map(season =>
                <MenuItem value={season.name}>{season.name}</MenuItem>
              )}
            </Select>

          </FormControl>
          <FormControl variant="outlined" size="small" className={style.selectColorFolder} >
            <MultiSelect
              onChange={onChangeFolder}
              options={
                colorFolderSeason && colorFolderSeason !== null ?
                 dataColorsFolder.colorsFolder.filter(colorFolder => colorFolder.name.includes(colorFolderSeason)).map(colorFolder => ({value: colorFolder.id, label: colorFolder.name}))
                : dataColorsFolder.colorsFolder.map(colorFolder => ({value: colorFolder.id, label: colorFolder.name}))
              }
              name="folder"
              label="Cartella"
              placeholder="Cartella"
              isMulti={false}
              defaultValue={null}
            >
               <MenuItem value={null}><em>Nessuno</em></MenuItem>
            </MultiSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {
            colorFolder && colorFolder !== null && colorFolder.id && colorFolder.id !== "" ? (
              <>
                <Typography variant="h6" style={{margin: "20px 0"}} fullWidth>
                  Cartella: <b><i>{colorFolder.name}</i></b>
                  <IconButton onClick={onOpenModalEditColorFolder} style={{float: "right", marginTop: "-10px"}}>
                    <EditIcon color="primary" fontSize="default" />
                  </IconButton>
                  <IconButton onClick={onOpenModalConfirmDeleteColorFolder} style={{float: "right", marginTop: "-10px"}}>
                    <DeleteIcon color="error" fontSize="default" />
                  </IconButton>
                </Typography>
                <Grid>
                  <FormControlLabel
                    className={style.selectAllWrapper}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={onChangeMassiveDelete}
                        name="all"
                        checked={allDeleteChecked}
                        disabled={search ? Object.getOwnPropertyNames(dataColors.colors.filter(item => item.colorFolder.id === colorFolder.id  && item.hex.toLowerCase().includes(search) )).length - 1 > 0 ? false : true : Object.getOwnPropertyNames(dataColors.colors.filter(item => item.colorFolder.id === colorFolder.id )).length - 1 > 0 ? false : true}
                      />
                    }
                    label="Seleziona tutto"
                  />
                  <Button
                    variant="contained"
                    size="small"
                    disabled={checkboxMassiveDelete.length > 0 ? false : true}
                    onClick={onOpenModalConfirmDeleteAllColor}
                  >
                    Elimina selezionati
                  </Button>
                  <TextField
                    style={{margin:"0 0 10px"}}
                    className={style.searchField}
                    variant="outlined"
                    margin="normal"
                    id="search"
                    label="Cerca Colore"
                    name="Cerca Colore"
                    size="small"
                    value={search}
                    onChange={onChangeSearch}
                    style={{zIndex: 0}}
                  />
                </Grid>
                <TableContainer component={Paper}>
                  <Table className={style.table} size="small">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="left"></StyledTableCell>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="center">Colore</StyledTableCell>
                        <StyledTableCell align="center">Codice</StyledTableCell>
                        <StyledTableCell align="center">Azioni</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {
                        search ? (
                          dataColors.colors.filter(item => item.colorFolder.id === colorFolder.id && item.hex.toLowerCase().includes(search.toLowerCase())).map((item, index) => (
                            <StyledTableRow key={index} >
                              <StyledTableCell align="left" style={{width: "50px"}}>
                                <Checkbox
                                  color="primary"
                                  name={item.id}
                                  onChange={onChangeMassiveDelete}
                                  checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">{index + 1}</StyledTableCell>
                              <StyledTableCell align="center">
                                <Avatar variant="rounded" src={item.image} alt={item.name} className={style.avatarColor}/>
                              </StyledTableCell>
                              <StyledTableCell align="center">{item.hex}</StyledTableCell>
                              <StyledTableCell align="center">
                                <IconButton onClick={() => onOpenUpdateColor(item)}>
                                  <EditIcon color="primary" fontSize="default" />
                                </IconButton>
                                <IconButton onClick={() => onOpenConfirmDeleteColor(item)}>
                                  <DeleteIcon color="error" fontSize="default" />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          dataColors.colors.filter(item => item.colorFolder.id === colorFolder.id ).map((item, index) => (
                            <StyledTableRow key={index} >
                              <StyledTableCell align="left" style={{width: "50px"}}>
                                <Checkbox
                                  color="primary"
                                  name={item.id}
                                  onChange={onChangeMassiveDelete}
                                  checked={checkboxMassiveDelete.indexOf(item.id.toString()) >= 0 ? true : false}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left">{index + 1}</StyledTableCell>
                              <StyledTableCell align="center">
                                {
                                  idColorImageUpdated !== undefined && idColorImageUpdated === item.id ? (
                                    <Avatar variant="rounded" src={item.image + "?" + Date.now()} alt={item.name} className={style.avatarColor}/>
                                  ) : (
                                    <Avatar variant="rounded" src={item.image} alt={item.name} className={style.avatarColor}/>
                                  )
                                }
                              </StyledTableCell>
                              <StyledTableCell align="center">{item.hex}</StyledTableCell>
                              <StyledTableCell align="center">
                                <IconButton onClick={() => onOpenUpdateColor(item)}>
                                  <EditIcon color="primary" fontSize="default" />
                                </IconButton>
                                <IconButton onClick={() => onOpenConfirmDeleteColor(item)}>
                                  <DeleteIcon color="error" fontSize="default" />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        )
                      }
                      {
                        fileColorImage && fileColorImage !== undefined && fileColorImage.length > 0  ? (
                          fileColorImage.map((item, index) => {
                            return (
                              <StyledTableRow key={"newColor"} >
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">{dataColors && dataColors !== undefined && dataColors.colors && dataColors.colors !== undefined ? dataColors.colors.filter(item => item.colorFolder.id === colorFolder.id ).length  + 1 + index : index}</StyledTableCell>
                                <StyledTableCell align="center">
                                  <Avatar variant="rounded" src={URL.createObjectURL(item)} alt={item.name} className={style.avatarColor}/>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <TextField
                                    InputLabelProps={{ shrink: true }}
                                    style={{width: "310px"}}
                                    name={index}
                                    value={addNewColorFormValues && addNewColorFormValues !== undefined ? addNewColorFormValues.index : ""}
                                    onChange={onChangeAddColorFormValues}
                                    variant="outlined"
                                    margin="normal"
                                    label="Codice"
                                    error={errorAddNewColor}
                                    helperText={errorAddNewColor ? "Inserire tutti i campi e verificare che il codice non sia esistente in questa cartella" : ""}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                              </StyledTableRow>
                            );
                          })

                        ) : (
                          <></>
                        )
                      }
                      <StyledTableRow key={"newColor"} >
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center">
                          <FormControl variant="outlined" margin="normal" fullWidth >
                            <div>
                              {
                                fileColorImage !== undefined && fileColorImage.name !== undefined ?  (
                                  <>
                                    <img src={URL.createObjectURL(fileColorImage)} className={style.productImage} width="50" height="50" alt={fileColorImage.name}/>
                                    <CloseIcon className={style.closeIconGalleryImage} onClick={deleteImageUploadedToAddImage}/>
                                  </>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="default"
                                    size="medium"
                                    className={style.button}
                                    startIcon={<CloudUploadIcon />}
                                    onClick={onOpenDropzone}
                                  />
                                )
                              }
                              <DropzoneDialog
                                open={openDropzone}
                                onSave={onSaveImage}
                                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                                showPreviews={true}
                                maxFileSize={5000000}
                                onClose={onCloseDropzone}
                                showFileNames={true}
                                showFileNamesInPreview={true}
                                showAlerts={true}
                                filesLimit={100}
                              />
                            </div>
                          </FormControl>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {
                            errorMessageAddNewColor  && errorMessageAddNewColor !== undefined ? (
                              <span className={style.errorHexDuplicated}>I seguenti codici: <b>{errorMessageAddNewColor}</b> non sono stati inseriti perchè già esistenti in questa cartella <b style={{marginLeft: "1rem", cursor: "pointer", color: "black" }} onClick={onRemoveMessageAddNewColor}>OK</b></span>
                            ) : (
                              <></>
                            )
                          }

                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            type='submit'
                            onClick={onSaveNewColor}
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={fileColorImage && fileColorImage !== undefined && fileColorImage.length > 0 ? false : true}
                          >
                            Aggiungi
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (<p style={{ margin: "0"}}>Nessuna cartella colori selezionata</p>)
          }

        </Grid>
      </Grid>
      <Modal
        open={openModalEditColorFolder}
        onClose={onCloseModalEditColorFolder}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalEditColorFolder}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Inserisci il nuovo nome della cartella
          </Typography>
          <TextField
            name="name"
            value={editColorFolderFormValues.name}
            onChange={onChangeEditColorFolderFormValues}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Nome"
            autoFocus
            error={errorEditColorFolder}
          />
          {
            errorEditColorFolder ? (
              <Typography variant="caption" color="error">Verificare che il nome inserito non sia già esistente</Typography>
            ) : (
              <></>
            )
          }
          <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
            <Grid item >
              <Button
                onClick={onCloseModalEditColorFolder}
                variant="contained"
                size="medium"
                color="primary"
              >
                Annulla
              </Button>
            </Grid>
            <Grid item >
              <Button
                onClick={() => editColorFolderById(colorFolder.id)}
                variant="contained"
                size="medium"
                color="primary"
              >
                Salva
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteColorFolder}
        onClose={onCloseModalConfirmDeleteColorFolder}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalConfirmDeleteColorFolder}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          {
            successDeleteFolder ? (
              <Typography variant="body1" align="center" style={{color: "green"}}>
                Cartella eliminata con successo
              </Typography>
            ) : (
              <>
                <Grid container xs={12} direction="row" justify="center" alignItems="center" >
                  <Typography variant="h4" color="error" align="center" gutterBottom >
                    Attenzione
                  </Typography>
                </Grid>
                <Grid container xs={12} direction="row" justify="center" alignItems="center" >
                  <Typography variant="body1" align="center" gutterBottom >
                    Sei sicuro di volere eliminare la cartella <b>{colorFolder?.name}</b> e tutti i colori al suo interno ?
                  </Typography>
                </Grid>
                <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                  <Grid item >
                    <Button
                      type='submit'
                      onClick={onCloseModalConfirmDeleteColorFolder}
                      variant="contained"
                      size="medium"
                      color="primary"
                    >
                      Annulla
                    </Button>
                  </Grid>
                  <Grid item >
                    <Button
                      type='submit'
                      onClick={deleteColorFolderById}
                      variant="contained"
                      size="medium"
                      color="primary"
                    >
                      Conferma
                    </Button>
                  </Grid>
                </Grid>
              </>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteColor}
        onClose={onCloseConfirmDeleteColor}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseConfirmDeleteColor}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare il colore <b>{colorToDelete.hex}</b> ?
            </Typography>
          </Grid>
          {
            loadingRemoveColor ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseConfirmDeleteColor}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={() => onRemoveColor(colorToDelete.id)}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalUpdateColor}
        onClose={onCloseUpdateColor}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseUpdateColor}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Typography variant="h5" color="primary" gutterBottom>
            Modifica colore
          </Typography>
          <TextField
            name="hex"
            value={colorToUpdate.hex}
            onChange={onChangeUpdateColorFormValues}
            variant="outlined"
            margin="normal"
            fullWidth
            label="HEX"
            error={errorUpdateColor}
            required
          />
          <FormControl variant="outlined" margin="normal">
            <div>
              {
                fileColorImage !== undefined && fileColorImage.name !== undefined ?  (
                  <>
                    <img src={URL.createObjectURL(fileColorImage)} className={style.productImage} width="100" height="100" alt={fileColorImage.name}/>
                    <CloseIcon className={style.closeIconGalleryImage} onClick={deleteImageUploadedToAddImage}/>
                  </>
                ) : (
                  <>
                    <EditIcon className={style.buttonEditUploadedImage} color="primary" fontSize="large" onClick={onOpenDropzone}/>
                    <img src={colorToUpdate.image} width="100" height="100" alt={colorToUpdate.name} />
                  </>
                )
              }
              <DropzoneDialog
                open={openDropzone}
                onSave={onSaveUpdateImage}
                acceptedFiles={["image/jpeg", "image/jpg", "image/png", "image/bmp"]}
                showPreviews={true}
                maxFileSize={5000000}
                onClose={onCloseDropzone}
                showFileNames={true}
                showFileNamesInPreview={true}
                showAlerts={true}
                filesLimit={1}
              />
            </div>
          </FormControl>
          {
            errorUpdateColor ? (
              <Typography variant="caption" color="error" style={{marginTop: "1rem", width: "100%"}}>Inserire tutti i campi e verificare che il nome non sia già esistente</Typography>
            ) : (
              <></>
            )
          }
          {
            loadingUpdateColor ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    onClick={onCloseUpdateColor}
                    //disabled={disabledSaveEditUser}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    onClick={onSaveUpdateColor}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
      <Modal
        open={openModalConfirmDeleteAllColor}
        onClose={onCloseModalConfirmDeleteAllColor}
      >
        <Grid container item xs={11} sm={11} md={5} lg={5} xl={5} className={style.paperModal}>
          <IconButton className={style.modalButtonCloseIcon} onClick={onCloseModalConfirmDeleteAllColor}>
            <CloseIcon className={style.modalCloseIcon} fontSize={"large"} />
          </IconButton>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="h4" color="error" align="center" gutterBottom >
              Attenzione
            </Typography>
          </Grid>
          <Grid container xs={12} direction="row" justify="center" alignItems="center" >
            <Typography variant="body1" align="center" gutterBottom >
              Sei sicuro di volere eliminare <b>{checkboxMassiveDelete.length}</b> {checkboxMassiveDelete.length === 0 ? "colore " : "colori "}?
            </Typography>
          </Grid>
          {
            loadingRemoveAllColor ? (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid className={style.modalActionContainer} container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={onCloseModalConfirmDeleteAllColor}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Annulla
                  </Button>
                </Grid>
                <Grid item >
                  <Button
                    type='submit'
                    onClick={deleteAllSelected}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Modal>
    </>
  );
};

export default Layout;
