import React from "react";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";
import Favorite from "@material-ui/icons/Favorite";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Tooltip from "@material-ui/core/Tooltip";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Style from "./style.js";

const noFavorites = (style, onGoToShop) => (
  <div className={style.paperNotFoundContent}>
    <p className={style.paperNotFoundText}>Opsss...</p>
    <span className={style.paperNotFoundEmoji} role="img" aria-label="emoji-thinking" aria-labelledby="emoji-thinking">💔</span>
    <p style={{ marginBottom:"1.5rem" }}>Non hai ancora aggiunto nessun prodotto ai preferiti</p>
    <Button
      className={style.resetFilterButton}
      size="small"
      variant="contained"
      color="secondary"
      onClick={onGoToShop}
      startIcon={<StorefrontIcon />}
    >Vai al negozio</Button>
  </div>
);

const Layout = ({ dataMyFavorites, onFavorite, onGoToShop, onViewProduct  }) => {
  const style = Style();
  return (
    <>
      {
        dataMyFavorites.myFavorites.length > 0 ? (
          <div style={{ marginBottom: "1rem", marginTop: "1rem"}}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit" className={style.breadCrumbsText} onClick={onGoToShop}>Shop</Typography>
              <Typography color="textPrimary">Preferiti</Typography>
            </Breadcrumbs>
            <div className={style.productsContainer}>
              <div className={style.productsList}>
                {
                  dataMyFavorites.myFavorites.map((item, index) => (
                    <>
                      <Paper key={index} elevation={3} className={style.productCard}>
                        <img src={item.image} className={style.productImage} alt={item.name} />
                        {
                          item.stock ? (
                            <div className={style.stockWrapper}>
                              <span className={style.stockText}>ESAURITO</span>
                            </div>
                          ) : (null)
                        }
                        <div className={style.productNameContainer}>
                          <div className={style.productInfo}>
                            <p className={style.productName}>{item.name} {item.fineness_name ? item.fineness_name : null}</p>
                            <p className={style.productCode}>Cod: {item.code}</p>
                          </div>
                          <p className={style.productPrice}>€{item.price}</p>
                        </div>
                        <div className={style.productFooter}>
                          <Tooltip title="Rimuovi" placement="right">
                            <FormControlLabel
                              className={style.productHeart}
                              control={<Checkbox icon={<FavoriteBorder />} checked={true} onClick={() => onFavorite(item.product_id)} checkedIcon={<Favorite style={{ color: "#ff5b60" }}/>} />}
                            />
                          </Tooltip>
                          <Button
                            className={style.productButton}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => onViewProduct(item.product_id)}
                            startIcon={<VisibilityIcon />}
                          >Visualizza</Button>
                        </div>
                      </Paper>
                    </>
                  ))
                }
              </div>
            </div>
          </div>
        ) : (noFavorites(style, onGoToShop))
      }
    </>
  );
};

export default Layout;
