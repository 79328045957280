
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(16),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    margin: "1rem",
    // backgroundColor: "var(--blue)",
    // color: "#FFFFFF",
    // "&:hover": {
    //   backgroundColor: "var(--blue)",
    //   opacity: 0.8,
    // },
  },
  modal:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody:{
    padding: "0.5rem 1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    width: "450px",
  },
  modalSubTitle:{
    marginTop: "1rem",
  },
  modalFooter:{
    marginTop: "1rem",
    marginBottom: "0.5rem",
  },
  modalSubmit: {
    backgroundColor: "var(--blue)",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "var(--blue)",
      opacity: 0.8,
    },
  },
  loading: {
    color: "#FFFFFF",
  },
  loginErrorMessage: {
    fontSize: "0.75rem",
    width: "100%",
  },
  textField: {
      background: "#ffffff",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  passwordText: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
