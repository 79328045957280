import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./application";
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: 'MinionPro, Roboto, Arial, sans-serif',
  },
  palette: {
    primary: {
      // main: "rgba(130,70,51,1)" // This is an orange looking color
      // main: "#2c0b0f"
      main: "#471013"
    },
    secondary: {
      // main: "#ffcc80"
      main: "#e8e4e5"
    }
  },
});

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
