import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperModal: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    marginTop: theme.spacing(5),
    margin: 'auto',
  },
  modalButtonCloseIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  modalActionContainer: {
    padding: theme.spacing(2, 0, 0),
  },
  gridContainer:{
    marginTop: "0",
  },
  productImageContainer:{
    width: "100%",
    //minHeight: "425px",
    //maxHeight: "425px",
    borderRadius: "4px",
    position: "relative",
  },
  productImage:{
    width: "100%",
    //minHeight: "425px",
    //maxHeight: "425px",
    borderRadius: "4px",
  },
  productImageButton: {
    position: "absolute",
    bottom: "9px",
    right: "10px",
  },
  paperProductInfo: {
    minHeight: "636px",
  },
  productTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  productTitle: {
    padding: "1rem",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  productDescription: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  productSubDescription: {
    padding: "1rem 1rem 0 1rem",
  },
  productDescriptionText: {
    padding: "0.5rem 1rem 0 1rem",
  },
  addColorDiscountBtnWrapper: {
    display: "flex",
    alignItems: "end",
    '& div': {
      height: "max-content",
    }
  },
  productSize: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  productSizeText: {
    padding: "0.5rem 1rem 0 1rem",
  },
  productPrice: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  productPriceText: {
    padding: "0.5rem 1rem 1rem 1rem",
  },
  productBuy: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  tab:{
    marginTop: "1.5rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px 4px 0 0",
  },
  paperTab: {
    marginBottom: "1.5rem",
    borderRadius: "0 0 4px 4px",
  },
  gridGallery: {
    marginTop:"2.5rem",
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    borderRadius: "4px",
  },
  galleryImage: {
    borderRadius: "4px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  productColorsContainer: {
    padding: "0.5rem 1rem 0 1rem",
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "repeat(6, 1fr)",
      gridGap: "0.25rem",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(10, 1fr)",
      gridGap: "0.25rem",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(15, 1fr)",
      gridGap: "0.25rem",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "repeat(16, 1fr)",
      gridGap: "0.25rem",
    },
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "repeat(25, 1fr)",
      gridGap: "0.25rem",
    },
  },
  gridContainerBuy: {
    padding: "0.5rem 1rem 0 1rem",
  },
  select: {
    width: "100%",
  },
  quantity: {
    width: "100%",
  },
  buyButton: {
    width: "100%",
    backgroundColor: "var(--mate-green)",
    color: "(--text-inverted)",
    "&:hover": {
      backgroundColor: "var(--mate-green)",
      opacity: 0.8,
    },
  },
  productColorSelect:{
    width: "20px",
    height: "20px",
  },
  productSummary: {
    padding: "1rem 1rem 0 1rem",
    fontWeight: "bold",
  },
  productSummaryContainer: {
    padding: "0.5rem 1rem 1rem 1rem",
  },
  test: {
    display: "inline-flex",
    width: "100%",
    marginBottom: "1rem",
  },
  productSummaryImage: {
    width: "20px",
    height: "20px",
    marginRight: "1rem",
  },
  deleteButton: {
    color: "var(--mate-red)",
  },
  breadCrumbsText:{
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  cartButtonContainer:{
    textAlign: "right",
  },
  cartButton: {
    marginTop: "1rem",
    width: "100%",
    backgroundColor: "var(--mate-green)",
    color: "(--text-inverted)",
    "&:hover": {
      backgroundColor: "var(--mate-green)",
      opacity: 0.8,
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "33%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "24%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "22%",
    },
  },
  productSizeSelect :{
    padding: "0.5rem 1rem 0 1rem",
  },
  checkboxColorContainer:{
    display: "flex",
    marginRight: "1rem",
  },
  productPriceList:{
    padding: "0 1rem 0 1rem",
    display: "flex",
    alignItems: "baseline",
    fontSize: "1rem",
    fontWeight: "600",
  },
  colorSelectOptionContainer: {
    display: "flex",
  },
  sizeBySizeGroupWrapper: {
    margin: theme.spacing(4, 1, 0, 1),
    background: theme.palette.primary.main,
    color: "#ffffff",
    fontWeight: "600",
    padding: "0.25rem 0.75rem !important",
    textAlign: "center",
    minWidth: "50px",
  },
  productStocklabel: {
    fontWeight: "600",
  },
  buttonActionContainer: {
    padding: theme.spacing(3, 0, 2),
  },
  productStockCheckboxWrapper: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('md')]: {
      justifyContent: "flex-end",
    },
  },
  buttonEditUploadedImage: {
    position: 'absolute',
    right: '-20px',
    bottom: '-12px',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    color: 'white',
    padding: '0.5rem',
    '&:hover': {
      cursor: "pointer",
    },
  },
  buttonEditUploadedImageChanged: {
    position: 'absolute',
    right: '-20px',
    bottom: '-12px',
    background: 'red',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    color: 'white',
    padding: '0.5rem',
    '&:hover': {
      cursor: "pointer",
    },
  },
  buttonEditUploadedImageGallery: {
    marginTop: '-28px',
    marginRight: '-10px',
    position: 'relative',
    right: '0',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    color: 'white',
    padding: '0.5rem',
    float: 'right',
    '&:hover': {
      cursor: "pointer",
    },
  },
  buttonEditUploadedImageGalleryChanged: {
    marginTop: '-28px',
    marginRight: '-10px',
    position: 'relative',
    right: '0',
    background: 'green',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    color: 'white',
    padding: '0.5rem',
    float: 'right',
    '&:hover': {
      cursor: "pointer",
    },
  },
  addNewGalleryButtonWrapper: {
    width: "max-content",
    margin: "3rem auto 0",
  },
  closeIconGalleryImage: {
    color: "white",
    background: "red",
    borderRadius: "50px",
    marginLeft: "-12px",
    marginBottom: "-8px",
    width: "25px",
    height: "25px",
    '&:hover': {
      cursor: "pointer",
    },
  },
  galleryAddIcon: {
    width: "25px",
    height: "25px",
    borderRadius: "50px",
    color: "white",
    background: "#2eb52e",
  },
  checkedTagContainer: {
    border: "1px solid black",
    padding: theme.spacing(1, 2),
    background: "yellow",
  },
  tagContainer: {
    border: "1px solid black",
    padding: theme.spacing(1, 2),
  },
  si: {
    background: "lightgreen",
  },
  no: {
    background: "red",
  },

}));
