import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paperDashboardOne:{
    padding: "0.5rem",
    color: "#ffffff",
    fontWeight: "700",
    background: "linear-gradient(90deg, rgba(130,70,51,1) 0%, rgba(159,84,60,0.9640231092436975) 50%, rgba(130,70,51,1) 100%)",
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
    },
  },
  paperDashboardTwo:{
    padding: "0.5rem",
    color: "#ffffff",
    background: "linear-gradient(90deg, rgba(130,70,51,1) 0%, rgba(159,84,60,0.9640231092436975) 50%, rgba(130,70,51,1) 100%)",
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
    },
  },
  paperDashboardThree:{
    padding: "0.5rem",
    color: "#ffffff",
    background: "linear-gradient(90deg, rgba(130,70,51,1) 0%, rgba(159,84,60,0.9640231092436975) 50%, rgba(130,70,51,1) 100%)",
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
    },
  },
  paperDashboardFour:{
    padding: "0.5rem",
    color: "#ffffff",
    background: "linear-gradient(90deg, rgba(130,70,51,1) 0%, rgba(159,84,60,0.9640231092436975) 50%, rgba(130,70,51,1) 100%)",
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
    },
  },
  paperDashboardFive:{
    padding: "0.5rem",
    color: "#ffffff",
    background: "linear-gradient(90deg, rgba(130,70,51,1) 0%, rgba(159,84,60,0.9640231092436975) 50%, rgba(130,70,51,1) 100%)",
  },
  paperDashboardContent:{
    display: "grid",
    gridTemplateColumns: "10% 1fr",
    gridGap: "3rem",
  },
  paperDashboardInfo:{
    margin: "0 0 0 0",
    fontSize: "0.9rem",
    fontWeight: "lighter",
  },
  paperDashboardContentBig:{
    display: "grid",
    gridTemplateColumns: "10% 1fr",
    gridGap: "1rem",
  },
  paperRegister:{
    padding: "0.5rem",
  },
  paperLink:{
    padding: "0.5rem",
  },
  emptyButton:{
    marginLeft: '10px',
    color: "#ffffff",
    border: '1px solid #ffffff',
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
    },
  },
  generateRandomTextField: {
    fontSize: "14px",
  },
  generateRandom: {
    padding: "7px 20px 7px 20px",
    marginRight: "-14px",
    background:  theme.palette.primary.main,
    color: "white",
    borderRadius: "0 4px 4px 0",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
  buttonAddNew: {
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  buttonAddNewLink:{
    textDecoration: "none",
    background:  theme.palette.primary.main,
    color: "white",
    borderRadius: "4px",
    padding: "7px 20px 7px 20px",
    margin: "10px 15px 0 0",
    display: "inline-flex",
  }
}));
